.hand{
    cursor: pointer;
}
.example{
    font-size: 11px;
    color: #000;
}
.invalid-file-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 400px;
    background-color: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 1000;
    padding: 20px;
    text-align: center;
    font-family: Arial, sans-serif;
}

.popup-inner h2 {
    font-size: 16px;
    font-weight: 600;
    color: #344563;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.popup-message {
    font-size: 14px;
    color: #6b778c;
    line-height: 1.5;
    padding: 30px;
}

.popup-message .not-p {
    color: #ff4d4f;
    font-weight: 600;
}

.popup-message .second {
    color: #36b37e;
    font-weight: 600;
}

.popup-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.popup-buttons button {
    padding: 8px 24px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    outline: none;
    border: none;
    transition: background-color 0.3s ease;
}

.close-popup {
    background-color: #d3d3d3;
    color: #344563;
}

.close-popup:hover {
    background-color: #c0c0c0;
}

.retry-upload {
    background-color: #1671fa;
    color: #ffffff;
}

.retry-upload:hover {
    background-color: #0041a8;
}

.close-icon {
    font-size: 18px;
    color: #ff4d4f;
    cursor: pointer;
}

.size-exceed-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.size-exceed-popup .popup-inner {
    background-color: #fff;
    padding: 20px;
    max-width: 472px;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
}

.size-exceed-popup .popup-inner .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    color: #ff4d4d;
}

.size-exceed-popup .popup-inner h2 {
    font-size: 18px;
    font-weight: 500;
    color: #253858;
    margin-bottom: 15px;
}

.size-exceed-popup .popup-message {
    font-size: 16px;
    color: #555;
    margin-bottom: 20px;
}

.size-exceed-popup .popup-message .file-p {
    color: #ff4d4d;
    font-weight: 500;
}

.size-exceed-popup .popup-buttons {
    display: flex;
    justify-content: center;
    gap: 17px;
}

.size-exceed-popup .popup-buttons .close-popup {
    background-color: #ddd;
    border: none;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.size-exceed-popup .popup-buttons .close-popup:hover {
    background-color: #ccc;
}

.size-exceed-popup .popup-buttons .retry-upload {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.size-exceed-popup .popup-buttons .retry-upload:hover {
    background-color: #0056b3;
}
