
.edit_and_delete_action {
    display: flex;
    gap: 10px;
}
.edit_and_delete_action button {
    width: 36px;
    height: 36px;
    background: #E4EBFF;
    border: none;
    border-radius: 5px;
    transition: all .2s;
}
.edit_and_delete_action button:hover {
    box-shadow: 0px 4px 10px #e5edfd;
}
.roles_heading {
    margin: 30px 0 4px 0;
    font-size: 20px;
    font-weight: 600;
    color: #253858;
}