.mian-wrapper {
    background: #E5EDFD;
    height: 100vh;
    padding: 95px 30px 30px 325px;
    position: relative;
    overflow: auto;
    
}
.mian-content-wrapper {
    min-height: calc(100vh - 200px);
}
.pageheading h2 {
    font-size: 18px;
    color: #000000;
    margin: 0 0 15px 0;
}
.card-white {
    background: #fff;
    border: solid 1px #DEE7F4;
    border-radius: 15px;
    padding: 30px;
}
.mian-wrapper .Copyright {
    font-size: 14px;
    color: #9AACCF;
    margin: 30px 0 0 0;
}


@media (max-width:767px){
    .mian-wrapper {
        padding: 80px 10px 30px 106px;
    }
    .heading h2 {
        font-size: 16px !important;
    }
    .get-app-card{
        display: none;
    }
    .mian-content-wrapper .heading {
        flex-direction: column;
        align-items: flex-start !important;
    }
    .mian-content-wrapper .heading h2 {
        margin: 0 0 16px 0;
    }
    
    
}