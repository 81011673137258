.file-brackdown-wrap {
    margin: 25px 0 0 0;
}
.hand{
    cursor: pointer;
}

  
.file-brackdown-wrap .brackdown-item {
    background: #E5EDFD;
    margin: 0 0 15px 0;
    padding: 15px 20px;
    border-radius: 8px;
}
.file-brackdown-wrap .brackdown-item .data-entries .file-info-geted {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #253858;
    font-size: 15px;
}
.file-brackdown-wrap .brackdown-item .data-entries .file-info-geted span {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 7px;
    gap: 10px;
    background: #FFFFFF;
    border-radius: 50px;
    font-size: 12px;
}
.file-brackdown-wrap .brackdown-item .data-entries .file-info-geted span i {
    font-weight: 600;
    color: #F0594C;
    font-style: normal;
}
.file-brackdown-wrap .brackdown-item .filerownumber {
    display: flex;
    align-items: center;
    gap: 15px;
    color: #253858;
    font-size: 14px;
    font-weight: 500;
}
.file-brackdown-wrap .brackdown-item .filerownumber span i {
    font-style: normal;
    color: #34A853;
}
.file-brackdown-wrap .brackdown-item .data-entries p {
    margin: 4px 0 0 0;
    font-size: 13px;
    color: #455877;
}
.file-brackdown-wrap .brackdown-item .data-entries p span {
    color: #EA4335;
}
.formatting {
    color: #455877;
    font-size: 14px;
    margin: 0 0 20px 0;
}
.heading h2 {
    font-size: 26px;
    color: #1B386A;
    font-weight: 600;
    margin: 20px 0 20px 0;
}
.file-details-box {
    box-sizing: border-box;
    background: #FFFFFF;
    border: 2px dashed #91BBFF;
    box-shadow: 0px 4px 15px rgba(66, 133, 244, 0.13);
    border-radius: 10px;
    padding: 25px;
    position: relative;
}
.file-details-box .file-type-detail {
    display: inline-flex;
    align-items: center;
    gap: 14px;
}
.file-details-box .file-type-detail .file-icon {
    background: #EDF4FE;
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}
.file-details-box .file-type-detail .file-info {
    line-height: 19px;
}
.file-details-box .file-type-detail .file-info .file-name {
    color: #4E5153;
    font-weight: 600;
    font-size: 15px;
}
.file-details-box .file-type-detail .file-info .file-size {
    font-size: 13px;
    color: #9A9A9A;
}
.filextrecteddata {
    max-height: 362px;
    overflow: auto;
    margin: 20px 0 0 0;
}
.filextrecteddata table thead tr th {
    color: #000000;
    font-size: 15px;
    font-weight: 600;
    background: #e5edfd;
    border: solid 1px #C8D2E2;
    text-align: center;
    padding: 8px 0;
    position: sticky;
    top: 0;
}
.filextrecteddata table tbody tr td {
    color: #8392AA;
    font-size: 14px;
    text-align: center;
    border: solid 1px #C8D2E2;
    font-weight: 400;
}
.file-details-box .fullScreen .small-screen-icon {
    display: none;
}

.full-screen-data .mian-wrapper {
    padding: 100px 30px 30px 25px;
}
.full-screen-data .mian-wrapper .mian-content-wrapper .heading,
.full-screen-data .mian-wrapper .mian-content-wrapper .file-details-box .file-brackdown-wrap,
.full-screen-data .mian-wrapper nav.leftSideNav,
.full-screen-data .mian-wrapper .mian-content-wrapper .file-details-box .formatting,
.full-screen-data .mian-wrapper .mian-content-wrapper .file-details-box .checkbox-option,
.full-screen-data .file-details-box .fullScreen .fullscreen-icon {
    display: none;
}
.full-screen-data .mian-wrapper .mian-content-wrapper .file-details-box .filextrecteddata {
    margin-bottom: 20px;
    max-height: 630px;
}
.full-screen-data .file-details-box .fullScreen .small-screen-icon {
    display: block;
}
.full-screen-data div {
    will-change: auto !important;
}
.full-screen-data .file-details-box {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
}
.full-screen-data .mian-wrapper .mian-content-wrapper .file-details-box .filextrecteddata {
    margin-bottom: 20px;
    max-height: calc(100% - 110px);
}
img.edit_value {
    margin: 0 0 0 3px;
    cursor: pointer;
}
.error_in_file .error_messege {
    margin: 20px 0 4px 0;
    font-size: 18px;
    color: #EA4335;
    font-weight: 500;
}
.error_in_file p {
    font-size: 16px;
    font-weight: 300;
    color: #000;
    margin: 0;
}
body.modal-open:after {
    content: '';
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 25%);
    z-index: 9;
    backdrop-filter: blur(8px);
}