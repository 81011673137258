.student_data_wrapper .live_data_wrapper .sessions_data_cards_wrapper {
    border-radius: 0;
    padding: 0;
}
.live_data_wrapper .live_data_cards_wrapper .sessions_data_cards_wrapper .live_data_cards.total_sessions_dubble {
    display: flex;
    justify-content: space-between;
}
.live_data_wrapper .live_data_cards_wrapper .sessions_data_cards_wrapper .live_data_cards.total_sessions_dubble .live_data_card_value {
    font-size: 55px;
}
.live_data_wrapper .live_data_cards_wrapper .sessions_data_cards_wrapper .live_data_cards.total_sessions_dubble .devider_v {
    width: 1px;
    height: 100%;
    background: #C8D2E2;
}
.program_and_batch_filters ul {
    padding: 0;
    list-style: none;
    margin: 20px 0 0px 0;
    display: flex;
    align-items: center;
    gap: 8px;
    overflow: auto;
}
.program_and_batch_filters ul li {
    font-size: 13px;
    color: #000000;
    background: #F0F3FB;
    display: flex;
    align-items: center;
    height: 32px;
    padding: 0 13px;
    border-radius: 5px;
    cursor: pointer;
    white-space: nowrap;
}

.program_and_batch_filters ul li.selected{
    background-color: #4285F4;
    color: #fff;
}
.action_buttons {
    display: flex;
    align-items: center;
    gap: 10px;
}
.action_buttons span {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 3px;
    cursor: pointer;
}
.action_buttons span:hover{
    background-color: #eee;
}
.table_layout table tbody tr {
    position: relative;
}
.table_layout table tbody tr td .form-check input:checked:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgb(66 133 244 / 6%);
    z-index: 0;
}
.table_layout table tbody tr td .form-check label.form-check-label {
    position: relative;
    z-index: 2;
}
.or_devider {
    position: relative;
}
.or_devider:before {
    content: '';
    height: 1px;
    width: 100%;
    background: #CDCDCD;
    position: absolute;
    left: 0;
    top: -3px;
}
.or_devider h3 {
    position: absolute;
    left: 0;
    right: 0;
    top: -12px;
    font-size: 16px;
    color: #7F7F80;
    background: #fff;
    margin: 0 auto 0;
    max-width: fit-content;
    padding: 0 10px;
}

@media (max-width:1024px){
    .table_layout table.table tbody tr td, .table_layout table.table thead tr th{
        padding: 10px 12px;
        vertical-align: middle;
        white-space: nowrap;
    }
}
@media (max-width:767px){
    .program_filter {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column-reverse;
        gap: 10px;
    }
    .live_data_wrapper .live_data_cards_wrapper .sessions_data_cards_wrapper .live_data_cards.total_sessions_dubble .live_data_card_value {
        font-size: 35px;
    }
}