.search_colleges .search_input {
    position: relative;
    display: flex;
}
.search_colleges .search_input .search_icon {
    position: absolute;
    left: 0;
}
.search_colleges .search_input input {
    height: 44px;
    border-radius: 10px;
    border: solid 1px #C9D0DA;
    padding: 0 0 0 43px;
    margin: 0 15px 0 0px;
    outline: none;
}
.search_colleges .search_input .search_icon {
    position: absolute;
    left: 14px;
    top: 12px;
}
.section_heading {
    margin: 30px 0 10px 0;
}
.section_heading h4 {
    font-size: 20px;
    color: #253858;
}
.table_layout {
    overflow: hidden;
    border: solid 1px #E4EBFF;
    border-radius: 10px;
}
.table_layout table.table {
    margin: 0;
}
.table_layout table.table tbody tr td, .table_layout table.table thead tr th {
    border-color: #E4EBFF;
    color: #253858;
    font-size: 15px;
    padding: 14px 12px;
    vertical-align: middle;
}
.table_layout table.table thead tr th {
    font-weight: 500;
}
.table_layout table.table tbody tr:last-child td {
    border-bottom: transparent;
}
.table_layout table.table tbody tr td .action_btn {
    background: #eee;
    padding: 3px 10px;
    border-radius: 5px;
    cursor: pointer;
}
.table_layout table.table tbody tr td .action_btn.active {
    background: #E7F5EB;
    color: #34A853;
}
.table_layout table.table tbody tr td .action_btn.expired {
    background: #FFEEED;
    color: #EA4335;
}
.plan_status {
    text-align: right;
    color: #EA4335;
}
.select_time_periods {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 20px 0 -10px 0;
}
.select_time_periods h5 {
    margin: 0;
    font-size: 16px;
}
.select_time_periods ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    position: relative;
    gap: 15px;
}
.select_time_periods ul li {
    position: relative;
}
.select_time_periods ul li span {
    padding: 5px 12px;
    display: block;
    background: #E5EDFD;
    border-radius: 5px;
    font-size: 14px;
    color: #000000;
    transition: all .2s;
}
.select_time_periods ul li input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    cursor: pointer;
}
.select_time_periods ul li input:checked + span {
    background: #136EF1;
    color: #fff;
}
.expired_date {
    cursor: pointer;
    padding: 0 3px;
}
.expired_date:hover {
    background: #f1f5fd;
}
.select_time_periods .selected_date {
    flex: 1;
    text-align: right;
}
.date{
    font-size: 11px;
    color: #999999;
}