/* Sidebar Styles */
.leftSideNav {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 300px;
    padding: 100px 25px 33px 25px;
    background: #fff;
    border-right: solid 1px #E5EDFD;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition: width 0.3s ease;
    overflow: auto;
}

.leftSideNav ul {
    margin: 0 0 20px 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}



.leftSideNav ul li a {
    display: flex;
    align-items: center;
    text-decoration: none;
    background: #fff;
    padding: 11px 15px;
    border-radius: 12px;
    color: #9fabbc;
    gap: 10px;
    transition: all 0.5s;
    position: relative;
}
.leftSideNav ul li a:hover {
    background: #ecf0f7;
}
.leftSideNav ul li a span {
    font-size: 15px;
}
.leftSideNav ul li a.active,
.leftSideNav ul li.current-page a {
    background: #4285F4;
    color: #fff;
}
.leftSideNav ul li a .completed_check {
    position: absolute;
    right: 13px;
    display: block;
    
}
.leftSideNav ul li a.disabled-link .completed_check{
    display: none;
}


.leftSideNav ul li.disabled {
    color: #9fabbc;
    margin-top: 0;
    margin-bottom: 0;
    pointer-events: none;
    opacity: 0.5;
}

.leftSideNav ul li .nav-icon img.gray {
    display: inline;
}

.leftSideNav ul li .nav-icon img.white {
    display: none;
}

.leftSideNav ul li a.active .nav-icon img.gray,
.leftSideNav ul li.current-page .nav-icon img.gray {
    display: none;
}

.leftSideNav ul li a.active .nav-icon img.white,
.leftSideNav ul li.current-page .nav-icon img.white {
    display: inline;
}

@media screen and (max-width: 768px) {
    .leftSideNav {
        width: 95px;
        padding: 70px 10px 20px 10px;
    }

    .leftSideNav ul li a {
        justify-content: center;
        padding: 5px 0;
        flex-direction: column;
        align-items: center;
        gap: 0;
        border-radius: 5px;
    }

    .leftSideNav ul li a span {
        display: block;
        font-size: 10px;
        text-align: center;
    }
}
