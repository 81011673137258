.modal-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
}

.modal-wrap .modaloverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 10px;
    background: rgba(23, 38, 75, 0.8);
    backdrop-filter: blur(10px);
}

.modal-wrap .modalinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 30px;
    width: 90%;
    max-width: 1100px;
    padding: 50px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    display: flex;
    gap: 20px;
    overflow: hidden;
}

.modal-wrap .modalinner button.close {
    position: absolute;
    top: 20px;
    right: 20px;
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.welcome-modal-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 20px;
    text-align: left;
    position: relative;
}

.welcome-modal-left h2 {
    font-size: 26px;
    color: #4285F4;
    font-weight: 500;
    margin: 0;
}

.welcome-modal-left h4 {
    font-size: 20px;
    color: #253858;
    margin: 10px 0;
}

.welcome-modal-left p {
    color: #9AA0A6;
    font-size: 14px;
    margin-bottom: 20px;
}

.welcome-graphic {
    margin-top: 20px;
}

.welcome-graphic img {
    width: 100%;
    max-width: 255px;
}

.welcome-modal-right {
    flex: 1;
    background: #F1F5FD;
    padding: 25px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.welcome-modal-right h2 {
    font-size: 18px;
    font-weight: 500;
    color: #253858;
    margin-bottom: 20px;
    text-align: center;
}

.questions-item {
    background: #DCE8FF;
    color: #253858;
    font-weight: 500;
    padding: 15px;
    margin: 10px 0;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    width: 100%;
}

.bg-shap1 {
    position: absolute;
    top: -70px;
    left: 380px;
    width: 78px;
    height: 78px;
    opacity: 0.6;
}

.bg-shap2 {
    position: absolute;
    top: 346px;
    left: 0%;
    transform: translateX(-50%);
    width: 97px;
    height: 97px;
    opacity: 0.6;
}

.bg-shap3 {
    position: absolute;
    bottom: -50px;
    right: -580px;
    width: 80px;
    height: 80px;
    opacity: 0.6;
}
