.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.hand{
  cursor: pointer;
}

.tabs button {
  flex: 1;
  padding: 10px;
  cursor: pointer;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px 4px 0 0;
}
.form-group.row {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 16px;
}

.form-group.row > *:last-child {
  margin-right: -24px !important;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.input-wrapper.half-width {
  width: 50%;
}

.input-wrapper.last-name {
  width: 60%;
}

.input-wrapper label {
  font-weight: 500;
}

.input-wrapper input {
  height: 48px;
  padding: 0 12px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  outline: none;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.input-wrapper input::placeholder {
  color: #aaa;
  font-size: 14px;
}

.input-wrapper input:focus {
  border-color: #007bff;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.2);
}

@media (max-width: 768px) {
  .form-group.row {
    flex-direction: column;
    gap: 12px;
  }

  .input-wrapper.half-width,
  .input-wrapper.last-name {
    width: 100%;
  }

  .form-group.row > *:last-child {
    margin-right: 0;
  }
}

@media (max-width: 576px) {
  .input-wrapper label {
    font-size: 12px;
  }

  .input-wrapper input {
    height: 44px;
    font-size: 14px;
  }

  .form-group.row {
    gap: 8px;
  }

  .form-group.row > *:last-child {
    margin-right: 0;
  }
}

.tabs .active-tab {
  background-color: #fff;
  border-bottom: none;
}

.form-group {
  margin-bottom: 16px;
  text-align: left;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
}


.login-btn {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-options {
  margin-top: 16px;
  text-align: center;
  color: #8A8A8A;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
}

.forgot-password,
.signup-link,
.google-btn {
  display: block;
  color: #007bff;
  text-decoration: none;
}

.remidme-and-forgot a.forgot-password {
  margin: -23px 0 0 0;
  color: #136EF1;
  font-weight: 400;
  font-size: 14px;
}

.google-btn {
  background-color: #4285F4;
  color: white;
  padding: 10px;
  border-radius: 4px;
  border: none;
}

.authpage-wrapper {
  display: flex;
  height: 100vh;
}

.authpage-wrapper .login-container,
.authpage-wrapper .auth-graphic {
  width: 100%;
  max-width: 50%;
}

.authpage-wrapper .auth-graphic {
  background: #136EF1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.authpage-wrapper .login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  overflow: auto  ;
}
#google-signin-button .nsm7Bb-HzV7m-LgbsSe-bN97Pc-sM5MNb {
  justify-content: center;
}
#google-signin-button .nsm7Bb-HzV7m-LgbsSe-bN97Pc-sM5MNb span {
  flex-grow: unset;
}

.login-container .auth-box {
  max-width: 450px;
  margin: auto;
  width: 100%;
}

.login-container .auth-box h1 {
  font-size: 30px;
  font-weight: 700;
  color: #253858;
  margin: 50px 0 0 0;
  line-height: 35px;
}

.login-container .auth-box h2 {
  margin: 0;
  margin-bottom: 10px;
  font-size: 16px;
  color: #8A8A8A;
  font-weight: 300;
  max-width: 290px;
}

form.login-form {
  margin: 25px 0 0 0;
}

.auth-box .tabs {
  gap: 10px;
  background: #E5EDFD;
  padding: 7px 10px 7px 20px;
  border-radius: 8px;
  margin: 30px 0 25px 0;
  justify-content: flex-start;
  align-items: center;
}

.auth-box .tabs h4 {
  font-weight: 500;
  font-size: 14px;
  margin: 0;
}

.auth-box .tabs button {
  padding: 8px 20px;
  border: none;
  outline: none;
  border-radius: 8px;
  background: transparent;
  flex: none;
  font-size: 14px;
  color: #253858;
  font-weight: 400;
}

.auth-box .tabs button.active-tab {
  background: #C1D8FB;
  color: #136ef1;
  font-weight: 600;
}

.remidme-and-forgot {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.remidme-and-forgot .form-group label {
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 5px;
  cursor: pointer;
}

.remidme-and-forgot .form-group label input[type="checkbox"] {
  width: 16px;
  height: 16px;
}

.remidme-and-forgot .form-group label {
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 5px;
  font-size: 14px;
  color: #767D89;
}

.circle {
  stroke-dashoffset: 400px;
  stroke-dasharray: 400px;
  stroke-width: 34.5px;
}

#blueCircle {
  stroke-dashoffset: 130px;
  stroke-dasharray: 130px;
}

@keyframes strokeAnimRed {
  0% {
    stroke-dashoffset: 401px;
  }
  100% {
    stroke-dashoffset: 55px;
  }
}

@keyframes strokeAnimYel {
  0% {
    stroke-dashoffset: 401px;
  }
  100% {
    stroke-dashoffset: 170px;
  }
}

@keyframes strokeAnimGreen {
  0% {
    stroke-dashoffset: 401px;
  }
  100% {
    stroke-dashoffset: 230px;
  }
}

@keyframes strokeAnimBlue {
  0% {
    stroke-dashoffset: 130px;
  }
  100% {
    stroke-dashoffset: 0px;
  }
}

#redCircle {
  animation: strokeAnimRed 1.5s ease 0.2s forwards;
}

#yelCircle {
  animation: strokeAnimYel 1.5s ease 0.2s forwards;
}

#greenCircle {
  animation: strokeAnimGreen 1.5s ease 0.2s forwards;
}

#blueCircle {
  animation: strokeAnimBlue 1.5s ease 0.2s forwards;
}

.authpage-wrapper .login-container.otpform .auth-box h2 {
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 6px;
}

.login-container .auth-box h2 span {
  font-weight: 500;
  color: #263238;
}

.auth-box form.otp-form {
  margin: 20px 0 0 0;
}

.auth-box form.otp-form .form-group.otp-inputs {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 15px;
    justify-content: center;
    margin: 30px 0 40px 0;
}

.auth-box form.otp-form .form-group.otp-inputs input.otp-input {
  background: #DEEAFD;
  border: none;
  width: 55px;
  height: 55px;
  text-align: center;
  color: #263238;
  font-size: 22px;
  font-weight: 600;
  outline: none;
}

.otp-form .login-options.Resendotp {
  font-weight: 300;
  margin: 20px 0 20px 0;
}

.otp-form .login-options.Resendotp a.signup-link {
  font-weight: 500;
}

.authpage-wrapper .login-container.otpform {
  text-align: center;
  position: relative;
}

.authpage-wrapper .login-container.otpform .auth-box h2 {
  margin: 0 auto;
}

.authpage-wrapper .login-container .logo {
  position: relative;
  left: 0;
  top: 0;
  transition: all .5s;
}

.auth-box form.otp-form span.error-message {
  margin: 11px 0 0 0;
}

.authpage-wrapper .login-container.otpform .logo {
  position: absolute;
  left: 50px;
  top: 50px;
  transition: all .5s;
}

.form-group.row {
  display: flex;
  justify-content: space-between;
}

.form-group.row > * {
  flex: 1;
  margin-right: 10px;
}

.form-group.row > *:last-child {
  margin-right: 0;
}

.btn.send-otp {
  margin-left: 10px;
  height: 100%;
  align-self: flex-end;
}

.phone-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.phone-input-wrapper input {
  width: 100%;
  padding-right: 100px;
  border-radius: 8px;
  border: 1px solid #ccc;
  height: 40px;
  box-sizing: border-box;
}

.send-otp-btn {
  position: absolute;
  right: 10px; 
  top: 50%;
  transform: translateY(-137%); 
  background-color: #89d0f4;
  color: #ffffff; 
  border: 1px solid #ccc;
  border-radius: 4px; 
  padding: 0 15px;
  height: 30px;
  font-size: 12px; 
  cursor: pointer; 
  box-sizing: border-box;
}

.send-otp-btn:not(:disabled):hover {
  background-color: #d1d1d1; 
}

.or {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.or::before,
.or::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #ddd;
}

.or::before {
  margin-right: 10px;
}

.or::after {
  margin-left: 10px;
}

.or span {
  font-size: 0.9rem;
  color: #999;
}

@media (max-width: 1200px) {
  .authpage-wrapper {
    padding: 0 20px;
  }

  .login-container {
    padding: 40px 20px;
  }

  .login-container .auth-box {
    width: 100%;
  }

  .login-container .auth-box h1 {
    font-size: 28px;
  }

  .login-container .auth-box h2 {
    font-size: 15px;
  }
}

@media (max-width: 992px) {
  .authpage-wrapper {
    flex-direction: column; 
    height: auto; 
  }

  .authpage-wrapper .auth-graphic {
    display: none; 
  }

  .authpage-wrapper .login-container,
  .authpage-wrapper .auth-graphic {
    max-width: 100%;
  }

  .authpage-wrapper .login-container {
    padding: 30px 20px;
  }

  .login-container .auth-box {
    width: 90%;
  }

  .form-group.row {
    flex-direction: column; 
    align-items: center;
  }

  .form-group.row > * {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .remidme-and-forgot {
    flex-direction: column; 
    align-items: center;
  }

  .or {
    flex-direction: column; 
    align-items: center;
  }

  .or span {
    font-size: 0.8rem;
  }
}

.form-group-otp {
  margin-bottom: 20px;
}

.phone-number-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.phone-number-wrapper input {
  padding-right: 80px; 
}

.verified-badge {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-89%);
  background-color: #28a745;
  color: #fff;
  padding: 5px 0px 5px 12px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  font-size: 12px;
  height: 26px;
}

.verified-icon {
  margin-right: 5px;
}

.otp-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0px;
}

.otp-inputs {
  display: flex;
  gap: 8px;
}

.otp-input {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f1f5f9;
  transition: border-color 0.3s ease;
}

.otp-input:focus {
  border-color: #007bff;
  outline: none;
  background-color: #ffffff;
}

.resend-span span {
  color: blue;
  font-weight: 800;
}

.verify-btn {
  background-color: #28a745;
  color: #fff;
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 86px;
}

.resend-link {
  font-size: 12px;
  color: #66666a;
  text-decoration: none;
  cursor: pointer;
  margin-top: 5px;
  align-self: flex-start;
}

.resend-link span {
  font-size: 12px;
  color: #0d00ff;
  text-decoration: none;
  cursor: pointer;
  margin-top: 5px;
  font-weight: 600;
  padding-right: 10px;
  align-self: flex-start;
}

@media (max-width: 768px) {
  .login-container .auth-box h1 {
    font-size: 24px;
  }

  .login-container .auth-box h2 {
    font-size: 14px;
  }

  .tabs button {
    padding: 8px;
    font-size: 12px;
  }

  .send-otp-btn {
    font-size: 10px;
    padding: 0 10px;
  }

  .login-btn {
    padding: 8px;
  }

  .error {
    font-size: 12px;
  }

  .remidme-and-forgot {
    flex-direction: column;
    align-items: center;
  }

  .remidme-and-forgot .form-group {
    margin-bottom: 10px;
  }
}

@media (max-width: 576px) {
  .login-container .auth-box h1 {
    font-size: 20px;
  }

  .login-container .auth-box h2 {
    font-size: 12px;
  }

  .tabs button {
    padding: 6px;
    font-size: 10px;
  }

  .send-otp-btn {
    font-size: 8px;
    padding: 0 8px;
  }

  .login-btn {
    padding: 6px;
  }

  .error {
    font-size: 10px;
  }

  .or span {
    font-size: 0.7rem;
  }
}

.eyes-icon {
  position: absolute;
  right: 10px;
  top: 23%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 1.2rem;
}

.eye-icon {
  position: absolute;
  right: 16px;
  top: 8px;
  cursor: pointer;
  font-size: 1.2rem;
}
.form-group {
  position: relative;
}
.forgot-pass{
  color: #136EF1;
  display: flex;
  justify-content: end;
}
.btn.disabled, .btn:disabled, fieldset:disabled .btn {
  border-color: #ccc;
}
div#google-signin-button {
  text-align: center;
}
#google-signin-button iframe {
  display: inline-block !important;
  margin: auto !important;
}
.auth-box form.otp-form span.error-message {
  margin: -30px 0 30px 0;
}