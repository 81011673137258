.program_filter .search_wrap {
    width: 100%;
    max-width: 420px;
}
.program_filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.program_filter .search_wrap .form-group {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 0;
}
.program_filter .search_wrap .form-group .search_icon {
    position: absolute;
    left: 13px;
    top: 9px;
}
.program_filter .search_wrap .form-group input[type="text"] {
    padding: 10px 10px 10px 40px;
    text-overflow: ellipsis;
    outline: none;
}
.running_batchs_programs_list .accordion .accordion-item {
    border: none;
    border-bottom: solid 1px #C2CCDE;
    border-radius: 0;
}
.running_batchs_programs_list .accordion .accordion-item .accordion-header .accordion-button {
    padding: 10px 12px 10px 0;
    font-size: 15px;
    color: #8796B0;
    font-weight: 400;
    border-radius: 0;
    box-shadow: none;
    background: transparent;
}
.running_batchs_programs_list .accordion .accordion-item .accordion-header .accordion-button::after {
    background-size: 15px;
    background-position: center;
}
.running_batchs_programs_list .running_batch_heading {
    margin: 40px 0 0px 0;
    font-size: 18px;
    color: #253858;
    font-weight: 600;
}
.running_batchs_programs_list .accordion .accordion-item .accordion-header .accordion-button[aria-expanded="true"] {
    border-bottom: solid 1px #EFF4FF;
}
.running_batchs_programs_list .accordion .accordion-item .accordion-body {
    background: #EFF4FF;
}
.running_batchs_programs_list .accordion .accordion-item .accordion-body h4 {
    font-size: 18px;
    font-weight: 600;
    color: #253858;
}
.running_batchs_programs_list .accordion .accordion-item .accordion-body ul {
    line-height: 30px;
    margin: 0;
}
.running_batchs_programs_list .accordion .accordion-item .accordion-body ul li {
    color: #627089;
}
.modal-content .add_programs ul {
    border: none;
    gap:10px;
}
.modal-content .add_programs ul li.nav-item button {
    border: none;
    color: #A3AED0;
    background: transparent;
    border-radius: 10px;
}
.modal-content .add_programs ul li.nav-item button.active {
    background: #E5EDFD;
    color: #4479FA;
}
.modal-content .add_programs ul li.nav-item:last-child {
    border-left: solid 1px #A3AED0;
    padding-left: 20px;
}
.running_batchs_programs_list .batches_card {
    background: #fff;
    padding: 20px;
    border-radius: 15px;
}
.running_batchs_programs_list .accordion .accordion-item .accordion-header .accordion-button[aria-expanded="true"] {
    border-bottom: solid 1px #EFF4FF;
}