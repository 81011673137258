.upload-file-box h3 {
    text-align: center;
    color: #1B386A;
    font-size: 26px;
}



.upload-file-box .upload-document {
    background: #fff;
    border: solid 1px #DEE7F4;
    border-radius: 15px;
    padding: 15px;
    margin: 25px 0 0 0;
}
.upload-file-box .upload-document .upload-document {
    background: #f6f9ff !important;
    margin: 8px 0 0 0;
    max-height: 195px;
    overflow: auto;
}
.header-containers span {
    color: #999;
    font-weight: 500;
}

.upload-file-box .upload-document h4 {
    text-align: center;
    color: #407BFF;
    font-size: 18px;
    margin: 0 0 30px 0;
}

.upload-file-box .upload-document .example-file {
    text-align: center;
    margin: 0 0 10px 0;
    overflow: hidden;
}
.upload-document .selectContainer .continue-button::after{
    display: none;
}
span.example.hand {
    text-decoration: none;
    margin: 0 0 0 5px;
    display: inline-flex;
    align-items: center;
    gap: 3px;
    color: #3b82f6;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.4px;
}
.upload-document .selectContainer {
    margin: 0 0 10px 0;
}

.spetext {
    text-align: center;
    margin-top: 0 !important;
}

.upload-file-box .upload-document .downloadsanple-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 0 0 6px 0;
    flex-wrap: nowrap;
}

.upload-file-box .upload-document .downloadsanple-wrap .DownloadSample {
    background-color: #8392AA;
    border: none;
    color: #fff;
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 10px;
    border-radius: 7px;
}

.upload-file-box .upload-document .downloadsanple-wrap .DownloadSample:hover {
    background: #8392AA;
}

.upload-file-box .upload-document .downloadsanple-wrap .info-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}

.upload-file-box .upload-document .downloadsanple-wrap span {
    font-size: 13px;
    color: #333;
    display: inline-block;
    margin-left: 10px;
}

.upload-file-box .upload-document .downloadsanple-wrap .info-wrapper .info-icon {
    width: 18px;
    height: 18px;
}

.upload-file-box .upload-document .note-text {
    background: #FFF5DA;
    border: solid 1px #FCD870;
    border-radius: 8px;
    padding: 10px;
}

.upload-file-box .upload-document .note-text p {
    font-size: 14px;
    color: #525252;
    margin: 0;
}

.spantexte {
    text-align: center;
    margin-top: 0 !important;
}

.upload-file-box .upload-document .note-text p span {
    color: #E0A700;
}
.file-details-table td.hand {
    color: #3b82f6;
    font-size: 12px;
}
