.mian-wrapper .toheader {
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    width: 100%;
    border-bottom: solid 1px #E5EDFD;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 35px;
    z-index: 9;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.mian-wrapper .toheader .userinfo {
    display: flex;
    align-items: center;
    gap: 20px;
}

.mian-wrapper .toheader .userinfo .profile-dropdown .dropdown-toggle {
    background: transparent;
    border: none;
    color: #676767;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-weight: 400;
}

.mian-wrapper .toheader .userinfo .profile-dropdown .dropdown-toggle .profile-img {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
    border: 2px solid #e5edfd;
}

.mian-wrapper .toheader .userinfo .profile-dropdown .dropdown-toggle::after {
    width: 12px;
    height: 8px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMSAxLjVMNiA2LjVMMSAxLjUiIHN0cm9rZT0iIzYyNjI2MiIgc3Ryb2tlLXdpZHRoPSIxLjgiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K);
    border: none;
    margin-left: 5px;
}

.mian-wrapper .toheader .userinfo .profile-dropdown .dropdown-menu {
    border: none;
    border-radius: 0px 0px 10px 10px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.08);
    margin: 9px 0 0 0;
    min-width: 180px;
}

.mian-wrapper .toheader .userinfo .profile-dropdown .dropdown-menu a.dropdown-item {
    display: flex;
    align-items: center;
    gap: 11px;
    font-size: 14px;
    color: #343D42;
    padding: 8px 15px;
}

.mian-wrapper .toheader .userinfo .notification .notification {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #f9f9f9;
    transition: all 0.4s;
}

.mian-wrapper .toheader .userinfo .notification .notification:hover,
.mian-wrapper .toheader .userinfo .profile-dropdown .dropdown-menu a.dropdown-item:hover {
    background: #e5edfd;
}

.mian-wrapper .toheader .userinfo .profile-dropdown .dropdown-menu a.dropdown-item a {
    display: flex;
    align-items: center;
    gap: 11px;
    font-size: 14px;
    color: #343D42;
    text-decoration: none;
}

.mian-wrapper .toheader .userinfo .profile-dropdown .dropdown-menu a.dropdown-item:active {
    background: #e5edfd;
}

.mian-wrapper .toheader .userinfo .notification .notification img {
    width: 24px;
    height: 24px;
    object-fit: contain;
}

.mian-wrapper .toheader .userinfo .profile-dropdown .dropdown-toggle span {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media (max-width: 767px) {
    .mian-wrapper .toheader {
        padding: 10px 20px;
    }

    .mian-wrapper .toheader .userinfo {
        gap: 10px;
    }

    .mian-wrapper .toheader .userinfo .profile-dropdown .dropdown-toggle span {
        font-size: 12px;
        display: none;
    }
    .toheader .logo {
        max-width: 121px;
    }
    .mian-wrapper .toheader .userinfo .profile-dropdown .dropdown-toggle{
        gap: 0px;
    }
}
