.fileuploading-box {
    text-align: center;
    margin: 30px 0 0 0;
    background: #FFFFFF;
    border: 2px dashed #91BBFF;
    box-shadow: 0px 4px 15px rgba(66, 133, 244, 0.13);
    border-radius: 10px;
    position: relative;
    min-height: 208px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.fileuploading-box .upload-files-text .upkoad-file-icon {
    background: #EEF1F5;
    width: 45px;
    height: 45px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    margin: 0 0 10px 0;
}
.data-process-modal .data-process-inner .procesing-content {
    padding: 20px 54px;
}
.fileuploading-box .upload-files-text p {
    color: #4E5153;
    margin: 0;
}
.fileuploading-box .upload-files-text span {
    color: #4479FA;
    font-weight: 500;
}
.fileuploading-box .fileformet-and-size {
    position: absolute;
    left: 0;
    bottom: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}
.fileuploading-box .fileformet-and-size p {
    margin: 0;
    font-size: 14px;
    font-weight: 300;
    color: #9A9A9A;
}
.fileuploading-box .fileformet-and-size p span {
    color: #34A853;
    font-weight: 500;
}
.fileuploading-box .fileformet-and-size p i {
    font-style: normal;
    font-weight: 500;
    color: #EA4335;
}
.fileuploading-box .file-droping-area {
    width: 100%;
    height: 100%;
    min-height: 204px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.data-process-modal {
    position: fixed;
    left: 0;
    top: 0;
    background: rgb(0 0 0 / 78%);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
}
.data-process-modal .data-process-inner {
    background: #fdfdfb;
    padding: 15px;
    border-radius: 15px;
    width: 100%;
    max-width: 470px;
}
.data-process-modal .data-process-inner h2 {
    text-align: left;
    font-size: 18px;
    color: #253858;
}
.data-process-modal .data-process-inner .modal-content img {
    max-width: 250px;
    margin: 0px auto -10px;
    object-fit: cover;
}
.data-process-modal .data-process-inner .modal-content p.procesing-content {
    color: #5D636B;
}
.data-process-modal .data-process-inner .modal-content p.procesing-content span {
    display: block;
    color: #DC761C;
}
.modal-open div {
    will-change: unset !important;
}
