.get-app-card {
    background: #FFEACF;
    text-align: center;
    padding: 25px 15px 25px 15px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 100%;
}
.get-app-card h3 {
    font-size: 17px;
    color: #0C336B;
    margin: 10px 0 0 0;
}
.get-app-card .download-btn-grp {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}
.get-app-card .download-btn-grp a {
    transition: all .4s;
    transform: scale(1);
}
.get-app-card .download-btn-grp a:hover {
    transform: scale(1.1);
}