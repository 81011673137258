.change-password-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  padding: 50px 40px;
  margin: 0;
  gap: 60px;
}
.form-section, .illustration-section {
  width: 50%;
}

.illustration-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.illustration-section img {
  width: 100%;
  max-width: 400px;
}

h2 {
  font-size: 24px;
  font-weight: 500;
  color: #333;
  margin-bottom: 8px;
}

p {
  font-size: 14px;
  color: #888;
  margin-bottom: 20px;
}

.input-group {
  margin-bottom: 16px;
}

.input-group label {
  display: block;
  font-size: 14px;
  color: #555;
  margin-bottom: 4px;
}

.input-group input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 6px;
  outline: none;
}

.save-button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 400;
}
.set-profile-details-card h2 {
  font-size: 18px;
}

.save-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .change-password-container {
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    max-width: 90%;
  }

  .form-section, .illustration-section {
    width: 100%;
  }

  h2 {
    font-size: 22px;
  }

  p {
    font-size: 13px;
  }

  .save-button {
    font-size: 15px;
  }
}

@media (max-width: 480px) {
  .change-password-container {
    padding: 15px;
  }

  h2 {
    font-size: 20px;
  }

  p {
    font-size: 12px;
  }

  .input-group input {
    padding: 8px;
    font-size: 13px;
  }

  .save-button {
    font-size: 14px;
    padding: 8px;
  }

  .illustration-section img {
    max-width: 100%;
  }
}
