.circular-progress {
    transform: rotate(-90deg); /* Rotates the circle for a top start */
  }
  
  .circular-progress-bg {
    fill: none;
    stroke: #1C4888;
  }
  
  .circular-progress-bar {
    fill: none;
    stroke: #1AD44C;
    stroke-linecap: round;
    transition: stroke-dashoffset 0.35s;
    transform: rotate(0.25turn);
    transform-origin: center;
  }