.hand{
    cursor: pointer;
}

.selected-session{
    background-color: #253858;
    
}

.date_range_and_tv_toggle .tv_screen_icon {
    margin-top: 0px;
    background: #fff;
    width: 38px;
    height: 38px;
    box-shadow: 0px 6px 20px rgb(66 133 244 / 10%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
}
.date_range_and_tv_toggle .rs-picker.rs-picker-daterange .rs-picker-input-group {
    height: 40px;
}
.date_range_and_tv_toggle {
    gap: 16px !important;
}
.card-white {
    background: #fff;
    border: solid 1px #DEE7F4;
    border-radius: 15px;
    padding: 30px;
}
.card-white .RealTime_Stamp {
    display: flex;
    align-items: center;
    gap: 10px;
}
.card_heading h4 {
    margin: 0;
    display: flex;
    align-items: center;
    gap: 10px;
}
.card_heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.card_heading h4 span {
    font-size: 20px;
    display: flex;
    align-items: center;
    color: #34A853;
    gap: 15px;
}
.card_heading h4 span {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #34A853;
    animation: pulse-animation 2s infinite;
  }  
  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgb(52 168 83 / 50%);
    }
    100% {
      box-shadow: 0 0 0 11px rgb(52 168 83 / 0%);
    }
  }
  .card-white.admin_card {
    padding: 15px;
}
.live_data_wrapper {
    display: flex;
    gap: 15px;
    margin: 15px 0 0 0;
}
.live_data_wrapper .sessionby_prgrams {
    width: 100%;
    max-width: 310px;
    background: #E5EDFD;
    padding: 15px;
    border-radius: 10px;
}
.live_data_wrapper .live_data_cards_wrapper {
    width: 100%;
    background: #ffffff;
    padding: 0;
}

.sessionby_prgrams .program_list ul {
    margin: 0;
    padding: 0 10px 0 0;
    list-style: none;
    max-height: 250px;
    overflow: auto;
}
.sessionby_prgrams .program_list ul::-webkit-scrollbar-track,
.top_performing_teacher .top_performing_teachers_list ul::-webkit-scrollbar-track
 {
     background-color: #C7D2EA;
     border-radius: 20px;
 }
 
.sessionby_prgrams .program_list ul::-webkit-scrollbar,
.top_performing_teacher .top_performing_teachers_list ul::-webkit-scrollbar
 {
     width: 6px;
     background-color: #C7D2EA;
     border-radius: 20px;
 }
 
.sessionby_prgrams .program_list ul::-webkit-scrollbar-thumb,
.top_performing_teacher .top_performing_teachers_list ul::-webkit-scrollbar-thumb
 {
     background-color: #8D99B0;
     border-radius: 20px;
 }



 .live_data_wrapper .sessionby_prgrams .tile_heading {
    color: #253858;
    font-size: 18px;
    margin: 0 0 16px 0;
}
 .sessionby_prgrams .program_list ul li {
    background: #fff;
    display: flex;
    align-items: center;
    padding: 8px 10px;
    margin: 0 0 15px 0;
    border-radius: 8px;
    justify-content: space-between;
    gap: 5px;
    font-size: 16px;
    color: #47505E;
    font-weight: 500;
}
.sessionby_prgrams .program_list ul li .selected{
    background: #253858;

}
.sessionby_prgrams .program_list ul li span {
    flex: 1;
    font-size: 12px;
    color: #8C97A7;
}
.sessionby_prgrams .program_list ul li h4 {
    margin: 0;
    font-size: 18px;
    color: #136EF1;
}
.live_data_wrapper .live_data_cards_wrapper {
    width: 100%;
    background: #ffffff;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 15px;
}
.MuiStack-root.css-nby9nv-MuiStack-root {
    overflow: visible;
    padding: 0;
}
.date_range_and_tv_toggle .MuiFormControl-root input.MuiInputBase-input {
    padding: 11px 10px 9px 35px;
    position: relative;
    z-index: 9;
    cursor: pointer;
}
.date_range_and_tv_toggle .MuiInputBase-formControl {
    padding: 0;
    z-index: 0;
}
.date_range_and_tv_toggle .MuiInputBase-formControl fieldset.MuiOutlinedInput-notchedOutline {
    border: none;
    background: #fff;
    padding: 0;
    box-shadow: 0px 6px 20px rgb(66 133 244 / 10%);
}
.date_range_and_tv_toggle .MuiInputBase-formControl:before {
    content: '';
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNyAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2IDUuMTY2NjdIMU01LjE2NjY3IDIuNjY2NjhWMS4wMDAwMU0xMS44MzMzIDIuNjY2NjdWMU0yLjY2NjY3IDEuODMzMzRIMi41ODMzM0MxLjcwODg4IDEuODMzMzQgMSAyLjU0MjIyIDEgMy40MTY2N1YxMy41QzEgMTQuNDIwNSAxLjc0NjE5IDE1LjE2NjcgMi42NjY2NyAxNS4xNjY3SDE0LjMzMzNDMTUuMjUzOCAxNS4xNjY3IDE2IDE0LjQyMDUgMTYgMTMuNVYzLjQxNjY4QzE2IDIuNTQyMjMgMTUuMjkxMSAxLjgzMzM0IDE0LjQxNjcgMS44MzMzNEgxNC4zMzMzTTcuNjY2NjcgMS44MzMzNEg5LjMzMzMzIiBzdHJva2U9IiNBMEE5QkEiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
    width: 17px;
    height: 16px;
    position: absolute;
    left: 10px;
    top: 14px;
    z-index: 9;
}
.date_range_and_tv_toggle .MuiInputBase-formControl:after {
    content: '';
    width: 12px;
    height: 7px;
    position: absolute;
    right: 13px;
    top: 19px;
    z-index: 9;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjgzODkzIDAuMzEwOTM0TDYuMDA1MzcgNC40MzU0NkwxMC4xNzE4IDAuMzEwOTM0QzEwLjU5MDYgLTAuMTAzNjQ1IDExLjI2NzEgLTAuMTAzNjQ1IDExLjY4NTkgMC4zMTA5MzRDMTIuMTA0NyAwLjcyNTUxMyAxMi4xMDQ3IDEuMzk1MjIgMTEuNjg1OSAxLjgwOTc5TDYuNzU3MDUgNi42ODkwN0M2LjMzODI1IDcuMTAzNjQgNS42NjE3NCA3LjEwMzY0IDUuMjQyOTUgNi42ODkwN0wwLjMxNDA5NCAxLjgwOTc5Qy0wLjEwNDY5OCAxLjM5NTIyIC0wLjEwNDY5OCAwLjcyNTUxMyAwLjMxNDA5NCAwLjMxMDkzNEMwLjczMjg4NiAtMC4wOTMwMTQ0IDEuNDIwMTMgLTAuMTAzNjQ1IDEuODM4OTMgMC4zMTA5MzRaIiBmaWxsPSIjQTNBRUQwIi8+Cjwvc3ZnPgo=);
    display: block;
}






.live_data_wrapper .live_data_cards_wrapper .sessions_data_cards_wrapper {
    background: #e5edfd;
    border-radius: 10px;
    display: flex;
    gap: 15px;
    padding: 15px;
}
.live_data_wrapper .live_data_cards_wrapper .sessions_data_cards_wrapper .live_data_cards {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 2px 2px rgb(0 0 0 / 5%);
    width: 100%;
    padding: 10px;
    height: 122px;
}
.live_data_wrapper .live_data_cards_wrapper .sessions_data_cards_wrapper .live_data_cards .live_data_card_heading {
    color: #253858;
    font-size: 17px;
    margin-bottom: 10px;
}
.live_data_wrapper .live_data_cards_wrapper .sessions_data_cards_wrapper .live_data_cards .live_data_card_value {
    font-size: 40px;
    font-weight: 600;
    margin: -7px 0 10px 0;
}
.live_data_wrapper .live_data_cards_wrapper .sessions_data_cards_wrapper .live_data_cards .live_data_progress_wrapper .live_data_progress_bar {
    background: #DDE3FA;
    height: 5px;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
}
.live_data_wrapper .live_data_cards_wrapper .sessions_data_cards_wrapper .live_data_cards .live_data_progress_wrapper .live_data_progress_bar span {
    position: absolute;
    left: 0;
    top: 0;
    height: 5px;
    background: #ccc;
    transition: all .3s;
}
.live_data_wrapper .live_data_cards_wrapper .sessions_data_cards_wrapper .live_data_cards .live_data_progress_wrapper ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #253858;
    font-size: 14px;
    font-weight: 500;
}
.live_data_wrapper .live_data_cards_wrapper .sessions_data_cards_wrapper .live_data_cards.active_data .live_data_card_value {
    color: #136EF1;
}
.live_data_wrapper .live_data_cards_wrapper .sessions_data_cards_wrapper .live_data_cards.active_data .live_data_progress_wrapper .live_data_progress_bar span {
    background: #136EF1;
}

.live_data_wrapper .live_data_cards_wrapper .sessions_data_cards_wrapper .live_data_cards.cancelled_data .live_data_card_value {
    color: #EA4335;
}
.live_data_wrapper .live_data_cards_wrapper .sessions_data_cards_wrapper .live_data_cards.cancelled_data .live_data_progress_wrapper .live_data_progress_bar span {
    background: #EA4335;
}

.live_data_wrapper .live_data_cards_wrapper .sessions_data_cards_wrapper .live_data_cards.completed_data .live_data_card_value,
.live_data_wrapper .live_data_cards_wrapper .sessions_data_cards_wrapper .live_data_cards.present_data .live_data_card_value {
    color: #34A853;
}
.live_data_wrapper .live_data_cards_wrapper .sessions_data_cards_wrapper .live_data_cards.completed_data .live_data_progress_wrapper .live_data_progress_bar span,
.live_data_wrapper .live_data_cards_wrapper .sessions_data_cards_wrapper .live_data_cards.present_data .live_data_progress_wrapper .live_data_progress_bar span {
    background: #34A853;
}

.live_data_wrapper .live_data_cards_wrapper .sessions_data_cards_wrapper .live_data_cards.proxy_data .live_data_card_value {
    color: #FBBC05;
}
.live_data_wrapper .live_data_cards_wrapper .sessions_data_cards_wrapper .live_data_cards.proxy_data .live_data_progress_wrapper .live_data_progress_bar span {
    background: #FBBC05;
}
.live_data_wrapper .live_data_cards_wrapper .sessions_data_cards_wrapper .live_data_cards.total_data h3.live_data_card_value {
    font-size: 55px;
    color: #2B3674;
    margin: 15px 0 0 0;
}

.card-white.admin_card.filters_card {
    padding: 0;
}
.card-white.admin_card.filters_card .filters_data ul.nav-tabs {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 200px;
    border: none;
    border-right: solid 1px #DEE7F4;
    margin: 0 !important;
    padding: 15px;
    gap: 15px;
}
.card-white.admin_card.filters_card .filters_data ul.nav-tabs .nav-item button.nav-link {
    width: 100%;
    background: #EEF4FF;
    border: none;
    border-radius: 8px;
    height: 43px;
    color: #8796B0;
}
.card-white.admin_card.filters_card .filters_data ul.nav-tabs .nav-item button.nav-link.active {
    background: #4285f4;
    color: #fff;
}
.card-white.admin_card.filters_card .filters_data ul.nav-tabs:before {
    content: 'Filter';
    font-size: 16px;
    color: #333333;
    font-weight: 600;
}
.card-white.admin_card.filters_card .filters_data {
    display: flex
;
}
.card-white.admin_card.filters_card .filters_data .tab-content {
    width: 100%;
}
.card-white.admin_card.filters_card .filters_data .tab-content .top_bar_filter_dropdown {
    padding: 10px 15px;
    border-bottom: solid 1px #DEE7F4;
    display: flex;
    justify-content: space-between;
}
.card-white.admin_card.filters_card .filters_data .tab-content .top_bar_filter_dropdown .form-group {
    margin: 0;
    max-width: 170px;
}
.progress_wrapper .circular-progress {
    transform: rotate(-180deg);
}
.progress_wrapper .circular-progress-bg {
    fill: none;
    stroke: #D1D6F5;
}
.progress_wrapper .circular-progress-bar {
    fill: none;
    stroke: #2B3674;
}
.programs_data_wrapper {
    display: flex;
    padding: 10px;
    justify-content: space-between;
}
.student_data_cards_wrapper {
    display: flex;
    flex-flow: column;
    gap: 15px;
    width: 100%;
    max-width: 310px;
}
.student_data_cards_wrapper .data_card_filter {
    background: #EEF4FF;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}
.student_data_cards_wrapper .data_card_filter .title_and_value h4 {
    font-size: 17px;
    color: #2B3674;
    font-weight: 600;
    margin: 0;
}
.student_data_cards_wrapper .data_card_filter .title_and_value .student_value {
    font-size: 36px;
    font-weight: 700;
}
.student_data_cards_wrapper .data_card_filter .progress_wrapper {
    position: relative;
}
.student_data_cards_wrapper .data_card_filter .progress_wrapper .total_value {
    position: absolute;
    top: 23px;
    width: 100%;
    text-align: center;
    font-weight: 600;
    color: #2B3674;
}
.student_data_cards_wrapper .data_card_filter .title_and_value .student_value.present_student {
    color: #34A853;
}
.student_data_cards_wrapper .data_card_filter .title_and_value .student_value.proxy_student {
    color: #FBBC05;
}
.student_data_cards_wrapper .data_card_filter .title_and_value .student_value.absent_student {
    color: #EA4335;
}
.programs_data_wrapper .program_graph {
    text-align: center;
    width: 100%;
}
.programs_data_wrapper .top_teacher_data {
    width: 100%;
}
.top_performing_teacher {
    background: #E5EDFD;
    padding: 10px;
    border-radius: 10px;
    width: 100%;
    max-width: 300px;
}
.top_performing_teacher .tile_heading {
    font-size: 18px;
    color: #253858;
    margin: 7px 0 15px 0;
}
.top_performing_teacher .top_performing_teachers_list ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 300px;
    overflow: auto;
    padding: 0 5px 0 0;
}
.top_performing_teacher .top_performing_teachers_list ul li {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px;
    border-radius: 5px;
    cursor: pointer;
}
.top_performing_teacher .top_performing_teachers_list ul li span {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 15px;
    font-weight: 500;
    color: #47505E;
}
.top_performing_teacher .top_performing_teachers_list ul li.selected span {
    color: #fff;
}
.top_performing_teacher .top_performing_teachers_list ul li.selected h4 {
    color: #fff;
}
.top_performing_teacher .top_performing_teachers_list ul li span img {
    width: 25px;
}
.top_performing_teacher .top_performing_teachers_list ul li h4 {
    margin: 0;
    color: #136EF1;
    font-size: 18px;
    font-weight: 500;
}
.top_performing_teacher .top_performing_teachers_list ul li.selected {
    background: #253858;
}



@media (max-width:1500px){
    .live_data_wrapper .live_data_cards_wrapper .sessions_data_cards_wrapper .live_data_cards .live_data_card_heading {
        font-size: 13px;
        white-space: nowrap;
    }
    .live_data_wrapper .live_data_cards_wrapper .sessions_data_cards_wrapper .live_data_cards .live_data_card_value {
        font-size: 30px;
    }    
    .live_data_wrapper .live_data_cards_wrapper .sessions_data_cards_wrapper .live_data_cards.total_data h3.live_data_card_value {
        font-size: 40px;
    }
    .live_data_wrapper .live_data_cards_wrapper .sessions_data_cards_wrapper .live_data_cards{
        height: auto;
    }
}

@media (max-width:1249px){
    .live_data_wrapper .live_data_cards_wrapper .sessions_data_cards_wrapper{
        flex-wrap: wrap;
    }
    .live_data_wrapper .live_data_cards_wrapper .sessions_data_cards_wrapper .live_data_cards {
        max-width: calc(50% - 8px);
        height: auto;
    }
    .live_data_wrapper .live_data_cards_wrapper {
        gap: 15px;
    }
    .sessionby_prgrams .program_list ul{
        max-height: 470px;
    }
    .card-white.admin_card.filters_card .filters_data {
        display: flex
    ;
        flex-direction: column;
    }
    .card-white.admin_card.filters_card .filters_data ul.nav-tabs {
        max-width: 100%;
        flex-direction: row;
        border-bottom: solid 1px #DEE7F4;
        align-items: center;
    }
    .top_performing_teacher{
        max-width: 250px;
    }
    .top_performing_teacher .tile_heading {
        font-size: 14px;
    }
    .student_data_cards_wrapper {
        max-width: 250px;
    }
    .student_data_cards_wrapper .data_card_filter .title_and_value h4 {
        font-size: 14px;
        font-weight: 500
    }
}


@media (max-width:991px){
    .live_data_wrapper,
    .programs_data_wrapper{
        flex-direction: column;
    }
    .live_data_wrapper .sessionby_prgrams {
        max-width: 100%;
    }
    .top_performing_teacher,
    .student_data_cards_wrapper {
        max-width: 100%;
    }
    .card_heading h4,
    .card_heading h4 span{
        font-size: 16px;
    }
    .card-white .RealTime_Stamp{
        font-size: 14px;
    }
}


@media (max-width:767px){
    .date_range_and_tv_toggle .date_range_picker {
        background: #fff;
        box-shadow: 0px 0px 10px rgb(0 0 0 / 13%);
        padding: 3px 10px;
        border-radius: 5px;
        font-size: 12px;
        line-height: 32px;
        margin: 0 0 15px 0;
    }
    .card_heading {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }
    .card_heading h4, .card_heading h4 span {
        font-size: 13px;
    }
    .card-white .RealTime_Stamp {
        font-size: 13px;
    }
    .card-white.admin_card {
        padding: 10px;
        border-radius: 5px;
    }
    .live_data_wrapper .sessionby_prgrams .tile_heading {
        font-size: 13px;
    }
    .sessionby_prgrams .program_list ul li {
        flex-direction: column;
        gap: 0;
        flex-wrap: wrap;
    }
    .live_data_wrapper .live_data_cards_wrapper .sessions_data_cards_wrapper .live_data_cards {
        max-width: calc(100% - 0px);
        height: auto;
    }
    .live_data_wrapper .live_data_cards_wrapper .sessions_data_cards_wrapper {
        padding: 10px;
    }
    .card-white.admin_card.filters_card .filters_data ul.nav-tabs {
        max-width: 100%;
        flex-direction: column;
        border-bottom: solid 1px #DEE7F4;
        align-items: flex-start;
        gap: 8px;
        padding: 10px;
    }
    .card-white.admin_card.filters_card .filters_data ul.nav-tabs li.nav-item {
        width: 100%;
    }
    .card-white.admin_card.filters_card .filters_data .tab-content .top_bar_filter_dropdown .form-group {
        margin: 0;
        max-width: 100%;
    }
    .card-white.admin_card.filters_card .filters_data .tab-content .top_bar_filter_dropdown {
        flex-direction: column;
        gap: 9px;
        padding: 10px;
    }
    .programs_data_wrapper{
        padding: 10px;
    }
    .top_performing_teacher .top_performing_teachers_list ul li span{
        font-size: 11px;
    }
    .top_performing_teacher .top_performing_teachers_list ul li h4{
        font-size: 13px;
    }
    .top_performing_teacher .top_performing_teachers_list ul li span img {
        width: 20px;
    }
}