.hand{
    cursor: pointer;
}
.note-tooltip {
    font-size: 12px;
  }
  
  .note-text {
    color: #E0A700;
  }
.example{
    text-decoration: underline;
    font-size: 11px;
    color: #000;
}


  
.loader {
    width: 16px;
    height: 16px;
    border: 2px solid transparent;
    border-top: 2px solid #ffffff; 
    border-radius: 50%;
    animation: spin 0.8s linear infinite;
  }

.file-details-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .file-details-table th,
  .file-details-table td {
    padding: 5px;
    text-align: left;
  }
  
  .file-details-table th {
    font-weight: 500;
    font-size: 13px;
    border-bottom:1px solid #e5e5e5;
  }
  
  .file-details-table td {
    font-size: 11px;
    color: #767D89;
    border-bottom: 1px solid #e5e5e5;
  }
  
  .cancel-button {
    background-color: #FFE1DE;
    color: red;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .cancel-button.gray-bg {
    background-color: #C8D2E2;
    color: #242424;
    opacity: 0.4;
    pointer-events: none;
}

  .cancels-button {
    background-color: #F0594C;
    color: #ffffff;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  

.header-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.header-containers {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 13px;
    font-weight:600;
}

.selectContainer {
    display: flex;
    justify-content: center;  /* Horizontally centers the button */
    align-items: center;
    margin-right: 25px;      /* Vertically centers the button */
  }

.batchsteps {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7rem;
}
.batchsteps .step-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #000000;
    gap: 15px;
    position: relative;
    z-index: 2;
}
.batchsteps .step-item .step-curcle {
    background: #9FABBC;
    width: 30px;
    height: 30px;
    border-radius: 60px;
    display: flex;
    justify-content: center;
    padding: 0;
    align-items: center;
}
.batchsteps .step-item .step-curcle i.dot {
    width: 8px;
    height: 8px;
    background: #F0F0F0;
    border-radius: 10px;
}
.batchsteps .step-item.completed .step-curcle {
    background: #34A853;
    padding: 5px 0 0 0;
}
.batchsteps:before {
    content: '';
    position: absolute;
    left: 0;
    top: 12px;
    width: 100%;
    height: 5px;
    background: #34A853;
    max-width: 300px;
    right: 0;
    margin: auto;
}
.noBatch {
    margin: 30px 0 70px 0;
}
.file-details-box.batch-wrapper {
    padding: 45px 25px 25px 25px;
}
.batch-wrapper .note-text {
    background: #FFF5DA;
    border: solid 1px #FCD870;
    border-radius: 8px;
    padding: 10px;
    margin: 30px 0 0 0;
}
.batch-wrapper .note-text p {
    font-size: 14px;
    color: #525252;
    margin: 0;
}
.batch-wrapper .note-text p span {
    color: #E0A700;
}

.batch-wrapper .createbatch-form {
    border: solid 1px #C8D2E2;
    padding: 15px;
    border-radius: 10px;
    max-width: 490px;
    margin: 50px auto 70px;
}
.batch-wrapper .createbatch-form h3 {
    font-size: 20px;
    color: #1B386A;
    margin: 0 0 20px 15px;
}
.batch-wrapper .createbatch-form .submit-btn {
    margin: 15px 0 0 0;
}
.batch-wrapper .createbatch-form .floating-label-input {
    margin: 0 0 20px 0;
}
.batch-wrapper .createbatch-form .multipleselect {
    margin-bottom: 20px;
}
.BatchList h3 {
    margin: 30px 0 0 0;
    font-size: 18px;
    color: #000000;
}
.BatchList .filextrecteddata {
    margin: 10px 0 0 0;
}
.BatchList .filextrecteddata table thead tr th:first-child {
    width: 100px;
}
.filextrecteddata table tbody tr td .added-Programs {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.more-Programs-list ul {
    list-style: auto;
    text-align: left;
    padding: 20px 0px 20px 30px;
    border-radius: 5px;
    line-height: 25px;
    margin: 0;
}
.filextrecteddata table tbody tr td .added-Programs .MoreOption {
    position: relative;
    background: #4285F4;
    color: #fff;
    width: 31px;
    height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    margin: 0 0 0 10px;
    cursor: pointer;
}
.more-Programs-list {
    display: none; /* Hidden by default */
}

.more-Programs-list.show {
    display: block;
    position: absolute;
    margin: auto;
    text-align: center;
    background: #fff;
    right: 80px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 12%);
    padding: 0 20px 0 0;
    border-radius: 5px;
    margin: 6px 0 0 0;
}
.more-Programs-list.show:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: #fff;
    transform: rotate(45deg);
    top: -5px;
    right: 102px;
    box-shadow: -1px -1px 1px rgb(0 0 0 / 36%);
}
.extraProgrammsList .tooltip-inner {
    background: #fff;
    max-width: max-content;
    box-shadow: 0px 3px 5px rgb(0 0 0 / 12%);
}
.extraProgrammsList .tooltip-arrow:before {
    border-bottom-color: #d1d1d1;
}
.extraProgrammsList .tooltip-inner ul {
    color: #000;
    text-align: left;
    list-style: auto;
    padding: 20px 0 0 20px;
    margin: 0 0 10px 0;
    white-space: nowrap;
}
.extraProgrammsList.tooltip.show {
    opacity: 1 !important;
}
.filextrecteddata table tbody tr td span {
    font-weight: 400;
}
p.warning+.floating-label-input {
    margin: 0;
}
.justify-content-center.modal-footer {
    margin-bottom: 10px;
}
.filextrecteddata.customScrollBar+p {
    font-size: 12px;
    margin: 4px 0 0 0;
}

.rightarrow {
    transform: translate(-50%, -50%);
    transform: rotate(-90deg);
    cursor: pointer;
}

.rightarrow span {
    display: block;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid white;
    border-right: 2px solid white;
    transform: rotate(-45deg);
    margin: -8px 0px;
    animation: animate 2s infinite;
}

.rightarrow span:nth-child(2) {
    animation-delay: -0.2s;
}

.rightarrow span:nth-child(3) {
    animation-delay: -0.4s;
}

@keyframes animate {
    0% {
        opacity: 0;
        transform: rotate(45deg) translate(-3px, -3px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(45deg) translate(12px, 12px);
    }
}
.next_step_action .btn.btn-primary {
    padding: 10px 30px 10px 20px;
}
.next_step_action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    margin: 25px 0 0 0;
}
.next_step_action h3 {
    margin: 0;
    font-size: 14px;
    color: #666;
}
.next_step_action a {
    text-decoration: none;
}