.MuiSelect-select {
    padding: 11px 14px !important;
    color: #737373 !important;
}
.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
    color: #848d9e;
    top: -4px;
    font-family: "Poppins", sans-serif !important;
    font-weight: 400;
    font-size: 14px;
}
.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-shrink {
    top: -1px;
    font-size: 18px;
    background: #fff;
    padding: 0 8px 0 0;
    color: #253858;
    font-weight: 500;
}
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary:hover fieldset.MuiOutlinedInput-notchedOutline {
    border-color: #cdcdcd;
}
.MuiPopover-root .MuiList-root.MuiMenu-list li.MuiMenuItem-root span.MuiButtonBase-root.MuiCheckbox-root {
    padding: 2px 12px 2px 0;
}

.cancel-button {
    background-color: #FFE1DE;
    color: red;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .select-button {
    background-color: #007bff;
    color: #ffffff;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .css-quhxjy-MuiInputBase-root-MuiOutlinedInput-root:after {
    content: '';
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjgzODkzIDAuMzEwOTM0TDYuMDA1MzcgNC40MzU0NkwxMC4xNzE4IDAuMzEwOTM0QzEwLjU5MDYgLTAuMTAzNjQ1IDExLjI2NzEgLTAuMTAzNjQ1IDExLjY4NTkgMC4zMTA5MzRDMTIuMTA0NyAwLjcyNTUxMyAxMi4xMDQ3IDEuMzk1MjIgMTEuNjg1OSAxLjgwOTc5TDYuNzU3MDUgNi42ODkwN0M2LjMzODI1IDcuMTAzNjQgNS42NjE3NCA3LjEwMzY0IDUuMjQyOTUgNi42ODkwN0wwLjMxNDA5NCAxLjgwOTc5Qy0wLjEwNDY5OCAxLjM5NTIyIC0wLjEwNDY5OCAwLjcyNTUxMyAwLjMxNDA5NCAwLjMxMDkzNEMwLjczMjg4NiAtMC4wOTMwMTQ0IDEuNDIwMTMgLTAuMTAzNjQ1IDEuODM4OTMgMC4zMTA5MzRaIiBmaWxsPSIjQTNBRUQwIi8+Cjwvc3ZnPgo=);
    width: 25px;
    height: 25px;
    display: block;
    position: absolute;
    right: 10px;
    top: 10px;
    background-repeat: no-repeat;
    background-position: center;
    pointer-events: none;
}
.css-quhxjy-MuiInputBase-root-MuiOutlinedInput-root .css-lohd6h-MuiSvgIcon-root-MuiSelect-icon,
.MuiSelect-icon.MuiSelect-iconOutlined.MuiSelect-iconOpen {
    display: none !important;
}
.MuiInputBase-formControl fieldset.MuiOutlinedInput-notchedOutline {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 14px;
    width: 100%;
}
.MuiInputBase-colorPrimary.MuiInputBase-formControl.Mui-focused fieldset{
    border-color: #ddd;
}
.MuiInputBase-colorPrimary.MuiInputBase-formControl.Mui-focused fieldset{
    border-width: 1px;
}
.MuiFormLabel-asterisk {
    color: red;
}
