body{
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  overflow: hidden;
  padding: 0;
  margin: 0;
}
*{
  box-sizing: border-box;
}
img{
  max-width: 100%;
}

.btn {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  border-radius: 8px !important;
}
.btn-danger{
  background-color: #F0594C;
  border: none;
}
.btn-danger:hover{
  background-color: #dd4a3d;
  border: none;
}
.btn-primary{
  background-color: #136EF1;
  border: none;
}
.btn-success {
  background: #34A853;
  border-color: #34A853;
  border: none;
}
.btn-success:hover {
  background: #209d41;
  border-color: #209d41;
  border: none;
}
.btn-secondary {
  background: #DADADA;
  border-color: #DADADA;
  color: #414141;
  border: none;
}
.btn-secondary:hover {
  background: #b9b9b9;
  border-color: #b9b9b9;
  color: #414141;
  border: none;
}
.btn.btn-outline-light {
  border-color: #9FABBC;
  color: #9FABBC;
}

.sm-text {
  font-size: 14px;
  font-weight: 400;
}
.primary-btn{background: #136ef1;}
.primary-btn:hover{background: #004fbd; color: #fff;}

.outline-light {border: solid 1px #E0E0E0;color: #4C5464;}
.outline-light svg {width: 40px;}
.outline-light:hover {border: solid 1px #E0E0E0;background: #E0E0E0;}

.modal-content {
  border-radius: 10px;
  border: none;
  box-shadow: none;
}
.modal-content .modal-header {
  border: none;
}
.modal-content .modal-header .modal-title {
  font-size: 15px;
  color: #1B386A;
  font-weight: 600;
}
.modal-content .modal-body {
  text-align: center;
}
.modal-content .modal-body p span {
  color: #34A853;
  font-weight: 600;
}
.modal-footer {
  border: none;
}



/* Scrollbar Styling */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    
}
 
::-webkit-scrollbar-track {
    background-color: transparent;
    -webkit-border-radius: 10px;
    border-radius: 0;
    border: solid 1px #C8D2E2;
    border-left: transparent;
    padding: 5px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    width: 4px;
    background: #b4b4b4; 
}

.mian-content-wrapper>div {
  will-change: unset !important;
  transform: none !important;
}
.modal_icon {
  max-width: 120px;
  margin: 0 auto 20px;
}
.modal_body .modal_heading {
  font-size: 20px;
}
.modal_body .modal_heading.succsess {
  color: #34A853;
}
.modal_body p {
  margin: 0;
  font-size: 16px;
}
.table_layout {
  overflow: auto !important;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper {
  max-height: 250px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper::-webkit-scrollbar-track
 {
     background-color: #C7D2EA;
     border-radius: 20px;
 }
 
 .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper::-webkit-scrollbar
 {
     width: 6px;
     background-color: #C7D2EA;
     border-radius: 20px;
 }
 
 .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper::-webkit-scrollbar-thumb
 {
     background-color: #8D99B0;
     border-radius: 20px;
 }
 .modal-body .warning svg {
  margin: 0 0 20px 0;
}
.modal-body .warning p {
  margin: 0;
}
.modal-body .warning p strong {
  font-size: 18px;
  font-weight: 600;
  color: #253858;
}
.file-details-table td .proceed-button {
  background: rgb(19 110 241);
  border: none;
  color: #fff;
  padding: 5px 12px;
  border-radius: 5px;
  font-size: 11px;
  letter-spacing: 0.5px;
}
.error_in_file p .example.hand {
  font-size: initial;
  color: #4479fa;
  margin: 0;
  font-weight: 400;
}