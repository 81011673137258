.table_layout td .name_and_img {
    display: flex;
    align-items: center;
    gap: 10px;
}
.table_layout td .name_and_img img {
    width: 25px;
    height: 25px;
    object-fit: cover;
    border-radius: 50px;
}