.checkbox-container {
  margin: 8px 0;
}
.checkbox-container .custom-checkbox input {
  position: relative;
  width: 0;
  height: 0;
  display: none;
}
.checkbox-container .custom-checkbox .checkbox-label {
  position: relative;
  display: flex;
  align-items: center;
  color: #253858;
  font-size: 14px;
  gap: 6px;
  cursor: pointer;
}
.checkbox-container .custom-checkbox .checkbox-label i {
  min-width: 16px;
  height: 16px;
  background: #E5EDFD;
  border-radius: 4px;
}
.checkbox-container .custom-checkbox .checkbox-label.checked i {
  background-color: #136ef1;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEwIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDQuNUwzLjUgN0w5IDEiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==);
  background-repeat: no-repeat;
  background-position: 3px 4px;
}
.form-check label.form-check-label {
  font-size: 14px;
  color: #848d9e;
  cursor: pointer;
}
