.welcome-box .user-welcome h4 {
    font-size: 18px;
    color: #6C6C6C;
    font-weight: 300;
}
.welcome-box .user-welcome h2 {
    font-size: 24px;
    color: #4285F4;
}
.welcome-box .user-welcome {
    margin: 0 0 30px 0;
}
.li-suggest{
  list-style-type: none;
}
.dashboard-content .onboadring-steps-list .steps-item {
    background: #E5EDFD;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-radius: 8px;
}
.dashboard-content .onboadring-steps-list .steps-item h4 {
    margin: 0;
    color: #0C336B;
    font-size: 17px;
}
.size-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #D1D1D1;
  border-radius: 8px;
  font-size: 14px;
  background-color: white; 
  color: #333; 
  appearance: none; 
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.size-select:focus {
  border-color: #3B82F6;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.3);
}

.size-select option {
  padding: 10px; 
}

.dashboard-content .onboadring-steps-list .steps-item h4 .data-process {
    background: #FBBC05;
    color: #000000;
    font-size: 12px;
    padding: 4px 15px;
    border-radius: 100px;
}
.dashboard-content .onboadring-steps-list .steps-item h4 .data-completed {
    background: #34A853;
    color: #fff;
    font-size: 12px;
    padding: 4px 15px;
    border-radius: 100px;
}
.dashboard-content .onboadring-steps-list .steps-item .progress-steps {
    color: #253858;
}
.dashboard-content .onboadring-steps-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.dashboard-content {
    display: flex;
    gap: 30px;
}
.dashboard-content .welcome-box {
    width: 100%;
}
.dashboard-content .set_up_your_profile_meter {
    width: 100%;
    max-width: 250px;
    background: #0C336B;
    padding: 20px;
    text-align: center;
    border-radius: 15px;
    position: relative;
}
.dashboard-content .set_up_your_profile_meter h3 {
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    max-width: 140px;
    margin: auto;
}
.dashboard-content .set_up_your_profile_meter .steps-meter {
    margin: 20px 0 0 0;
    position: relative;
}
.dashboard-content .set_up_your_profile_meter .steps-meter .stepslabel {
    position: absolute;
    left: 0;
    top: 49px;
    width: 100%;
    color: #fff;
}
.dashboard-content .set_up_your_profile_meter .stepsList {
    margin: 40px 0 0 0;
}
.dashboard-content .set_up_your_profile_meter .stepsList ul {
    padding: 0;
    list-style: none;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: #fff;
    font-size: 14px;
    text-align: left;
    font-weight: 300;
}
.dashboard-content .set_up_your_profile_meter .stepsList ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
}



.institute-type-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 8px;
}

.subtitle {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
}

.institute-button {
  border: none;
  padding: 25px 27px; 
  border-radius: 8px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative; 
}
.institute-button .icon-background {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  border-radius: 50%; 
  background-color: rgba(255, 255, 255, 0.8); 
  display: flex;
  align-items: center;
  justify-content: center;
}
.institute-button .icon {
  font-size: 24px; 
  margin-bottom: 5px; 
}
.college { background-color: #d9e5f9; color: #1a73e8; }
.university { background-color: #fddad4; color: #d23f31; }
.deemed { background-color: #c0eede; color: #0a8f6f; }
.community { background-color: #fbe4be; color: #e07a2f; }
.doctoral { background-color: #dfd4fc; color: #6c5ce7; }

.institute-button:hover {
  opacity: 0.9;
}

.other-input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  width: 100%;
  max-width: 320px;
}

.other-input-container label {
  font-size: 14px;
  color: #666;
  margin-bottom: 5px;
}

.other-input-container input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
}


.institute-type-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
  padding: 20px;
}
.institute-type-container .MuiOutlinedInput-root {
  padding: 5px 0 !important;
}
.institute-type-container .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 7.5px 4px 7.5px 15px !important;
}
.institute-type-container .MuiFormControl-fullWidth.singleselect .MuiOutlinedInput-root {
  padding: 0px 0 !important;
}
.progress-bar-container .progress {
  background: #e5edfd;
}
.card {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 4px 12px rgb(0 0 0 / 5%);
  padding: 30px;
  max-width: 660px;
  width: 100%;
  text-align: left;
  border: none;
}


.title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 8px;
  text-align: center;
}

.subtitle {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
  text-align: center;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: 100%;
}

.form-row {
  display: flex;
  gap: 10px;
}

.form-group label {
  font-size: 14px;
  color: #666;
  margin-bottom: 5px;
}

.form-group input,
.form-group select {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  width: 100%;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.back-button {
  padding: 12px 20px;
  background-color: #aaa;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.back-button:hover {
  background-color: #888;
}
.institute-button.college.selected { border:2px solid rgb(32, 78, 244)} 
.institute-button.university.selected { border:2px solid rgb(32, 78, 244) }
.institute-button.deemed.selected { border:2px solid rgb(32, 78, 244) } 
.institute-button.community.selected { border:2px solid rgb(32, 78, 244) } 
.institute-button.doctoral.selected { border:2px solid rgb(32, 78, 244) }
.continue-button {
  padding: 12px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.option-button.selected {
  border-color: rgb(32, 78, 244);
  color: #fff;
  background: rgb(32, 78, 244);
}
.option-button.initial, .option-button:hover {
  border-color: rgb(32, 78, 244);
  color: #fff;
  background: rgb(32, 78, 244);
}
.continue-button:hover {
  background-color: #0056b3;
}
.filextrecteddata table tbody tr td img[alt="Error"] {
  max-width: 12px;
  margin: -2px 0 0 0 !important;
}

.loader {
  width: 16px;
  height: 16px;
  border: 2px solid transparent;
  border-top: 2px solid #ffffff; /* Adjust the color for visibility */
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.question-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 750px;
  padding: 40px;
  text-align: center;
  background: #fff;
  box-shadow: 0px 30px 30px rgb(0 0 0 / 8%);
  border-radius: 20px;
  width: 100%;
}

.question-text {
  font-size: 1.6em;
  color: #333;
  font-weight: 700;
  margin-bottom: 30px;
  margin-top: 0;
}

.options-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 500px;
  justify-content: center;
  margin-bottom: 70px;
  min-height: 120px;
}

.option-button {
  background-color: #ffffff;
  border: 1px solid #d1d5db;
  color: #333;
  padding: 12px 24px;
  font-size: 1em;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 180px;
  text-align: center;
  max-height: fit-content;
}

.navigation-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100%;
  max-width: 500px;
}

.continue-button,
.skip-button {
  padding: 12px 20px;
  border-radius: 8px;
  font-size: 1em;
  cursor: pointer;
  transition: all 0.3s ease;
}

.continue-button {
  background-color: #007bff;
  color: #fff;
  border: none;
}

.continue-button:hover {
  background-color: #0056b3;
}

.skip-button {
  background-color: #f5f7fa;
  color: #333;
  border: 1px solid #d1d5db;
}

.skip-button:hover {
  background-color: #e2e8f0;
  border-color: #cbd5e0;
}


/* .progress-bar {
  width: 100%;
  max-width: 500px;
  height: 5px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 34px; 
} */

/* .progress {
  width: 11%;
  height: 100%;
  background-color: #007bff;
  transition: width 0.3s ease;
} */

.page-number {
  font-size: 0.9em;
  color: #6b7280;
  margin-top: 10px;
  text-align: center;
}

.slideDown{
	-webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.institute-info-container {
  max-width: 1800px !important;
  margin: auto;
  padding: 24px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.institute-info-title {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  color: #4A4A4A;
  margin-bottom: 8px;
}

.institute-info-description {
  text-align: center;
  color: #7A7A7A;
  margin-bottom: 24px;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.input-group {
  display: flex;
  flex-direction: column;
}

.input-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #4A4A4A;
  margin-bottom: 4px;
}

.input-field {
  width: 100%;
  padding: 10px;
  border: 1px solid #D1D1D1;
  border-radius: 8px;
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.input-field:focus {
  border-color: #3B82F6;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.3);
}

.select-container {
  position: relative;
}

.select-field {
  width: 100%;
  padding: 10px;
  border: 1px solid #D1D1D1;
  border-radius: 8px;
  appearance: none;
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.select-field:focus {
  border-color: #3B82F6;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.3);
}

.select-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #B0B0B0; 
  pointer-events: none;
}

.state-city-container {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.action-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
}

.back-button {
  padding: 10px 24px;
  color: #4A4A4A;
  background: transparent;
  transition: color 0.3s;
}

.back-button:hover {
  color: #7A7A7A;
}

.continue-icon {
  margin-left: 8px;
  transform: rotate(270deg); 
}
.institute-card .icon-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    background-color: #eaf1ff;
    border-radius: 50%;
    margin-right: 4px;
}
/* Step One Container */
.step-one-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  background-color: #f9fafb;
  min-height: 100vh;
  font-family: 'Inter', sans-serif;
}

/* Title */
.title {
  font-size: 24px;
  font-weight: 600;
  color: #0c336b;
  margin-bottom: 8px;
  text-align: center;
}

/* Subtitle */
.subtitle {
  font-size: 16px;
  color: #6c6c6c;
  margin-bottom: 24px;
  text-align: center;
}

/* Grid Layout */
.institute-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  width: 100%;
  max-width: 600px;
}

/* Institute Card (Default) */
.institute-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 12px;
  border: 1px solid #d1d5db; /* Light border */
  border-radius: 8px;
  background-color: #ffffff;
  cursor: pointer;
  transition: all 0.3s ease;
  gap: 12px;
}

.institute-card:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-color: #3b82f6;
}

.icon {
  font-size: 20px;
  color: #9ca3af; /* Muted gray for default icon */
}

/* Default Card Text */
.institute-card span {
  font-size: 14px;
  font-weight: 500;
  color: #6b7280; /* Muted gray text */
}

/* Selected Institute Card */
.institute-card.selected {
  background-color: #3b82f6; /* Blue background */
  color: #ffffff; /* White text */
  border-color: transparent; /* Remove border */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  transition: all 0.3s ease; /* Smooth transition */
  outline: none;
}
.continue-button:after {
  content: '';
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxOSAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuNjQwMTIgNi45OTk5NkwxNy4zNTk5IDYuOTk5OTZNMTcuMzU5OSA2Ljk5OTk2TDExLjY5MzQgMS4zMzQ5Nk0xNy4zNTk5IDYuOTk5OTZMMTEuNjkzNCAxMi42NjUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
  width: 19px;
  height: 14px;
}
.institute-card.selected .icon-circle {
  background-color: rgba(255, 255, 255, 0.2); /* Semi-transparent white background */
  border: none; /* No border */
  width: 40px; /* Default size */
  height: 40px; /* Default size */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%; /* Keeps it circular */
}

.institute-card.selected .icon {
  font-size: 20px;
  color: #ffffff; /* White icon */
}

.institute-card.selected span {
  color: #ffffff; /* White text */
}

/* Continue Button */
.continue-button-container {
  margin-top: 32px;
}

.continue-button {
  padding: 12px 30px !important;
  background-color: #3b82f6;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  gap: 10px;
}

.continue-button:hover {
  background-color: #2563eb;
}
.back-buttons {
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;

}
.card h2.title {
  margin: 0;
}

.institute-type-container>.error-message {
  margin: 10px 0 10px 0 !important;
  font-size: 13px;
}
.skip-button {
  padding: 12px 24px;
  background-color: #ffffff;
  color: #374151;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.skip-button:hover {
  background-color: #f9fafb;
  border-color: #3b82f6;
  color: #2563eb;
}

.progress-bar-container {
  width: 100%; 
  margin: 30px 0 20px 0;
  height: 5px; 
}
.institute-type-container .step-one-container {
  min-height: auto;
  background: transparent;
  width: 100%;
}

.institute-card span {
  font-size: 16px;
  font-weight: 400;
  color: #9FABBC;
  font-family: "Poppins", sans-serif !important;
}



 @-webkit-keyframes slide-bottom {
    0% {
      -webkit-transform: translateY(-25px);
              transform: translateY(-25px);
    }
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
  }
  @keyframes slide-bottom {
    0% {
      -webkit-transform: translateY(-25px);
              transform: translateY(-25px);
    }
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
  }
  
  .institute-type-container>.error-message {
    font-size: 13px;
    margin: 10px 0 !important;
    background: rgb(255 0 0 / 9%);
    padding: 8px 20px;
    border-radius: 6px;
}
button .loader {
  width: 15px;
  height: 15px;
}
.file-details-box .file-type-detail button.btn.primary-btn {
  padding: 4px 12px;
}

.question-container .navigation-buttons .continue-button[disabled] {
  cursor: not-allowed;
  opacity: 0.7;
}