/* FloatingLabelInput.css */

.floating-label-input {
    position: relative;
    margin: 0px 0 20px 0;
    width: 100%;
  }
  .floating-label-input input {
    width: 100%;
    padding: 10px 10px 10px 15px;
    border: 1px solid #CDCDCD;
    outline: none;
    font-size: 16px;
    transition: border-color 0.3s ease;
    color: #000000;
    height: 45px;
    border-radius: 7px;
}

.error-message {
  color: red;
  font-size: 11px;
  margin: 0px 0 20px 0;
  display: block;
}

.required-asterisk {
  color: red;
  margin-left: 4px;
}
  
  .floating-label-input input:focus {
    border: 1px solid #007BFF;
  }

  .floating-label-input.error input {
    border-color: red;  /* Red border for error */
  }


  
  .floating-label {
    position: absolute;
    left: 15px;
    top: 13px;
    font-size: 14px;
    color: #A0A9BA;
    transition: 0.2s ease all;
    pointer-events: none;
  }
  
  .floating-label-input.focused .floating-label,
  .floating-label-input input:not(:placeholder-shown) + .floating-label {
    top: -10px;
    font-size: 14px;
    color: #253858;
    background: #fff;
    padding: 0 10px;
    left: 5px;
  }
  
  .login-options {
    margin-top: 16px;
    text-align: center;
    color: #8A8A8A;
}

/* Add this to your style.css */

/* Apply styles to the disabled input */
.floating-label-input input:disabled {
  background-color: #FAFAFA !important;  /* Light gray background */
  color: #000000  !important   ;        /* Darker gray text */
  border: 1px solid #ccc;     /* Light border */
  cursor: not-allowed;        /* Show a disabled cursor */
}

/* Optional: If you want to keep the floating label even in the disabled state */
.floating-label-input input:disabled + .floating-label {
  color: #FAFAFA  !important ; /* Label color when disabled */
}
