.welcome-box .user-welcome h4 {
    font-size: 18px;
    color: #6C6C6C;
    font-weight: 300;
}

/* Loader styles */
.loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent overlay */
    backdrop-filter: blur(5px); /* background blur */
    z-index: 9999; /* ensures it appears on top of everything */
  }
  
  /* Loader itself */
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px; /* adjust the size of the loader */
    height: 50px;
  }
  
  /* Add any other custom loader styles here, if needed */

  .lds-ellipsis {
    /* change color here */
    color: #fff;
  }
  .lds-ellipsis,
  .lds-ellipsis div {
    box-sizing: border-box;
  }
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33.33333px;
    width: 13.33333px;
    height: 13.33333px;
    border-radius: 50%;
    background: currentColor;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  

.steps-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
}

.Onboarding_Completed {
    text-align: center;
}
.Onboarding_Completed img {
    max-width: 200px;
    margin: 10px 0 30px 0;
}
.Onboarding_Completed h4 {
    color: #34A853;
    font-size: 20px;
    font-weight: 600;
}
.hand{
    cursor: pointer;
}
.welcome-box .user-welcome h2 {
    font-size: 24px;
    color: #4285F4;
}
.welcome-box .user-welcome {
    margin: 0 0 30px 0;
}

.dashboard-content {
    display: flex;
    gap: 30px;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
}



.dashboard-content .onboadring-steps-list .steps-item {
    background: #E5EDFD;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-radius: 8px;
    position: relative;
}

.dashboards-content {
    display: flex;
    gap: 30px;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
}



.dashboards-content .onboadring-steps-list .steps-item {
    background: #E5EDFD;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-radius: 8px;
    position: relative;

}.dashboards-content .onboadring-steps-list .stepss-item {
    /* background: #E5EDFD; */
    color:"#9FABBC";
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 20px;
    border-radius: 8px;
    position: relative;
}



.completed-status {
    color: #44BFAF;
    font-weight: bold;
    padding-left: 5px;
}

.wait {
    color: gray;
    font-weight: normal;
}

.dashboard-content .onboadring-steps-list .steps-item .clickarea {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.dashboard-content .onboadring-steps-list .steps-item h4 {
    margin: 0;
    color: #0C336B;
    font-size: 17px;
    display: flex;
    align-items: center;
    font-weight: 500;
    line-height: normal;
}


.dashboard-content .onboadring-steps-list .steps-item h4 .data-process {
    background: #FBBC05;
    color: #000000;
    font-size: 12px;
    padding: 4px 15px;
    border-radius: 100px;
}

.dashboard-content .onboadring-steps-list .steps-item h4 .data-completed {
    background: #34A853;
    color: #fff;
    font-size: 12px;
    padding: 4px 15px;
    border-radius: 100px;
}

.dashboard-content .onboadring-steps-list .steps-item .progress-steps {
    color: #253858;
}

.dashboard-content .onboadring-steps-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.dashboard-content .welcome-box {
    width: 100%;
}

.dashboard-content .set_up_your_profile_meter {
    width: 100%;
    max-width: 250px;
    background: #0C336B;
    padding: 20px;
    text-align: center;
    border-radius: 15px;
    position: relative;
}

.dashboard-content .set_up_your_profile_meter h3 {
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    max-width: 140px;
    margin: auto;
}

.dashboard-content .set_up_your_profile_meter .steps-meter {
    margin: 20px 0 0 0;
    position: relative;
}

.dashboard-content .set_up_your_profile_meter .steps-meter .stepslabel {
    position: absolute;
    left: 0;
    top: 49px;
    width: 100%;
    color: #fff;
}

.dashboard-content .set_up_your_profile_meter .stepsList {
    margin: 40px 0 0 0;
}

.dashboard-content .set_up_your_profile_meter .stepsList ul {
    padding: 0;
    list-style: none;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: #fff;
    font-size: 14px;
    text-align: left;
    font-weight: 300;
}

.dashboard-content .set_up_your_profile_meter .stepsList ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dashboards-content .onboadring-steps-list .steps-item .clickarea {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.dashboards-content .onboadring-steps-list .steps-item h4 {
    margin: 0;
    color: #0C336B;
    font-size: 17px;
    white-space: nowrap;
}
.dashboards-content .welcome-box.programs {
    padding: 15px;
}

.dashboards-content .onboadring-steps-list .steps-item h4 .data-process {
    background: #FBBC05;
    color: #000000;
    font-size: 12px;
    padding: 4px 15px;
    border-radius: 100px;
}

.dashboards-content .onboadring-steps-list .steps-item h4 .data-completed {
    background: #34A853;
    color: #fff;
    font-size: 12px;
    padding: 4px 15px;
    border-radius: 100px;
}

.dashboards-content .onboadring-steps-list .steps-item .progress-steps {
    color: #253858;
}

.dashboards-content .onboadring-steps-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.dashboards-content .welcome-box {
    width: 100%;
}

.dashboards-content .set_up_your_profile_meter {
    width: 100%;
    max-width: 250px;
    background: #0C336B;
    padding: 20px;
    text-align: center;
    border-radius: 15px;
    position: relative;
}

.dashboards-content .set_up_your_profile_meter h3 {
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    max-width: 140px;
    margin: auto;
}

.dashboards-content .set_up_your_profile_meter .steps-meter {
    margin: 20px 0 0 0;
    position: relative;
}

.dashboards-content .set_up_your_profile_meter .steps-meter .stepslabel {
    position: absolute;
    left: 0;
    top: 49px;
    width: 100%;
    color: #fff;
}

.dashboards-content .set_up_your_profile_meter .stepsList {
    margin: 40px 0 0 0;
}

.dashboards-content .set_up_your_profile_meter .stepsList ul {
    padding: 0;
    list-style: none;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: #fff;
    font-size: 14px;
    text-align: left;
    font-weight: 300;
}

.dashboards-content .set_up_your_profile_meter .stepsList ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.slideDown {
    -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-bottom {
    0% {
        -webkit-transform: translateY(-25px);
        transform: translateY(-25px);
    }
    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}

@keyframes slide-bottom {
    0% {
        -webkit-transform: translateY(-25px);
        transform: translateY(-25px);
    }
    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}

.date_range_and_tv_toggle .MuiStack-root.css-1tewkwq {
    padding: 0;
}
.onboarding-completed-banner {
    background: #0c336b;
    margin: 20px 0 0 0;
    padding: 20px 40px;
    border-radius: 15px;
    max-width: 1279px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
    position: relative;
}
.onboarding-completed-banner .content_w {
    position: relative;
    padding: 10px 0 17px 100px;
}
.onboarding-completed-banner .content_w img {
    position: absolute;
    left: 0;
    top: -6px;
    max-width: 59px;
}
.onboarding-completed-banner .content_w h3 {
    color: #1ad44c;
    font-size: 24px;
    font-weight: 600;
}
.onboarding-completed-banner .content_w p {
    color: #fff;
    margin: 0;
}
.onboarding-completed-banner:after {
    content: '';
    position: absolute;
    left: -50px;
    top: -40px;
    width: 100px;
    height: 100px;
    background: rgb(255 255 255 / 11%);
    border-radius: 1000px;
}
.onboarding-completed-banner .btn.btn-primary {
    white-space: nowrap;
}
.data-process-modal .data-process-inner{
    position: relative;
}
.data-process-modal .data-process-inner .cong-animation {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}
.data-process-modal .data-process-inner .cong-animation dotlottie-player {
    width: 100% !important;
    height: 100% !important;
}



@media (max-width: 768px) {
    .sidebar {
        width: 100%;
        position: relative;
    }

    .dashboard-content {
        flex-direction: column;
        padding: 10px;
        width: 100%;
    }

    .dashboards-content {
        flex-direction: column;
        padding: 10px;
        width: 100%;
    }

    .onboadring-steps-list, .set_up_your_profile_meter {
        width: 100%;
    }

    .steps-item, .welcome-box, .set_up_your_profile_meter {
        padding: 10px;
        font-size: 14px;
    }

    .welcome-box .user-welcome h2,
    .welcome-box .user-welcome h4 {
        font-size: 18px;
        text-align: center;
    }

    .steps-meter {
        justify-content: center;
    }
    .steps-meter .stepslabel {
        font-size: 14px;
    }

    .status-icon {
        width: 15px;
        height: 15px;
    }
}

@media (max-width: 576px) {
    .dashboard-content {
        padding: 5px;
    }

    .dashboards-content {
        padding: 5px;
    }

    .steps-item h4, .set_up_your_profile_meter h3 {
        font-size: 16px;
    }

    .welcome-box, .steps-item, .set_up_your_profile_meter {
        padding: 8px;
    }
}
