/* General Container Styling */
.profile-header {
    font-size: 24px;
    font-weight: 500;
    color: #333;
    text-align: left;
}

.profile-header-actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.profile-edit-button {
    background-color: #4285f4;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
}

.profile-edit-button:hover {
    background-color: #2c6ed5;
}

.my-profile-container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.profile-card {
    width: 280px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    text-align: center;
    padding: 20px;
}

.profile-card-image-container-circle {
    width: 120px;
    height: 120px;
    overflow: hidden;
    border-radius: 50%;
    border: 2px solid #ddd;
    margin: 0 auto 10px;
}

.profile-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profile-card-name {
    font-size: 18px;
    font-weight: 500;
    color: #333;
    margin-top: 10px;
}

.profile-card-role {
    color: #555;
    font-size: 14px;
    margin-top: 5px;
}

.profile-details-card {
    flex: 1;
    padding: 20px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    position: relative;
}

.profile-details-card h2 {
    font-size: 20px;
    color: #333;
    margin-bottom: 15px;
}

.profile-info-group {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.profile-info-group svg {
    font-size: 1.2em;
    color: #555;
    margin-right: 10px;
}

.profile-info-group span {
    font-weight: 500;
    color: #555;
    width: 120px;
}

.profile-info-group p {
    margin: 0;
    color: #333;
}

.set-profile-container {
    display: flex;
    gap: 20px;
    justify-content: space-between;
}
.set-profile-form-grid .input-wrapper.designation #single-select-dropdown {
    padding: 11px 0 11px 15px !important;
}
.set-profile-form-grid .input-wrapper.designation .MuiInputBase-colorPrimary.MuiInputBase-formControl::after {
    top: 11px;
}

.set-profile-card {
    width: 300px;
    height: 395px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    text-align: center;
    position: relative;
    overflow: hidden;
}

.set-profile-card-image-container {
    position: relative;
    width: 100%;
    height: 220px;
    overflow: hidden;
    background-color: #f5f5f5;
}

.set-profile-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.set-profile-edit-icon-overlay {
    position: absolute;
    bottom: 15px;
    right: 15px;
    background-color: #4285f4;
    color: white;
    border-radius: 50%;
    width: 35px;  /* Set width */
    height: 35px; /* Set height equal to width for a perfect circle */
    padding: 0;   /* Remove padding to avoid the oval shape */
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  


.set-profile-edit-icon-overlay:hover {
    background-color: #2c6ed5;
}

.set-profile-card-info {
    padding: 20px;
    background-color: #fff;
}

.set-profile-card-name {
    font-size: 20px;
    font-weight: 500;
    color: #333;
    margin: 0;
}

.set-profile-card-role {
    color: #555;
    font-size: 14px;
    margin-top: 5px;
}

.set-profile-details-card {
    flex: 1;
    padding: 20px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
}

.set-profile-form-grid {
    display: flex;
    gap: 5px 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
}
.set-profile-form-grid .input-wrapper {
    max-width: calc(50% - 10px);
}
.set-profile-form-grid .input-wrapper.designation {
    max-width: 100%;
}

.set-profile-form-grid input,
.set-profile-form-grid select {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.set-profile-save-button {
    background-color: #4285f4;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    align-self: flex-end;
    transition: background-color 0.3s ease;
}

.set-profile-save-button:hover {
    background-color: #2c6ed5;
}
.set-profile-form-grid .otp-section {
    margin: -5px 0 0 0;
}
.set-profile-form-grid .otp-section .otp-inputs input.otp-input {
    height: 30px;
    width: 30px;
    padding: 0;
}
.set-profile-form-grid .otp-section .otp-inputs button.verify-btn {
    margin: 0;
    padding: 0 16px;
    font-size: 14px;
}



@media screen and (max-width: 768px) {
    .my-profile-container,
    .set-profile-container {
        flex-direction: column;
    }

    .profile-header-actions {
        justify-content: center;
    }

    .profile-card,
    .set-profile-card {
        margin: auto;
        width: 100%;
    }

    .profile-details-card,
    .set-profile-details-card {
        padding: 20px;
    }
}
