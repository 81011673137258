.program_filter.changerequest .search_wrap {
    width: 100%;
    max-width: 350px;
}
.table_layout.change-request-data td .name_and_img {
    display: flex;
    align-items: center;
    gap: 10px;
}
.table_layout.change-request-data td .name_and_img img {
    width: 25px;
    height: 25px;
    object-fit: cover;
    border-radius: 50px;
}
.change-request-data .action_buttons .request_action_btn {
    width: 100%;
    max-width: 170px;
    border: none;
    background: #eee;
    height: 35px;
    border-radius: 6px;
    outline: none;
}
.change-request-data .action_buttons .request_action_btn.new_request {
    background: #2B3674;
    color: #fff;
}
.change-request-data .action_buttons .request_action_btn.approved {
    background: #DAF0E0;
    color: #34A853;
}
.change-request-data .action_buttons .request_action_btn.flagged {
    background: #F8E9DD;
    color: #DC761C;
}
.change-request-data .action_buttons .request_action_btn.denied {
    background: #FEE5E2;
    color: #EA4335;
}
.change-request-data table.table tbody .new_request_type td {
    background: #f0f4fb;
    border-color: #d6deed;
}
.request_types {
    text-align: left;
    margin: 0 0 20px 0;
}
.request_types h4 {
    color: #6F7C8E;
    font-size: 15px;
    font-weight: 400;
    margin: 0 0 1px 0;
}
.request_types h3 {
    font-size: 17px;
}
.request_types.Reasonforchange {
    margin: 0;
}
.request_types p {
    margin: 0;
    font-weight: 500;
}

@media (max-width:767px){
    .program_filter .search_wrap .form-group {
        flex-direction: column;
    }
}